<template>
  <div :class="unique_id">
    <v-card-text v-if="show_launch_config && !finished">
      <div>
        <v-layout row wrap justify-space-around>
          <v-flex xs12 md11>
            <component
              :is="exercise.id + '-config'"
              ref="exerciseLaunchConfig"
              v-model="config"
              regimen_launch
            />
          </v-flex>
        </v-layout>
        <v-layout row wrap justify-space-around>
          <v-flex md1>
            <v-btn large color="primary" @click="launch_exercise_from_config">{{
              $t("common.launch")
            }}</v-btn>
          </v-flex>
        </v-layout>
      </div>
    </v-card-text>

    <v-alert
      v-if="verification_errors.length > 0"
      color="error"
      icon="$error"
      outlined
      class="ml-12 mr-12 mt-6"
    >
      <template v-if="verification_errors.length == 1">
        {{ verification_errors[0] }}
      </template>
      <ul v-else>
        <li v-for="(err, index) of verification_errors" :key="index">err</li>
      </ul>
    </v-alert>

    <div v-if="!error_loading_exercise">
      <v-alert
        v-if="bad_screen_size"
        color="error"
        icon="$error"
        :title="$t('exercise_launch.screen_size_too_small')"
        >{{
          $t("exercise_launch.your_screen_is_too_small_to_run_this_exercise")
        }}</v-alert
      >

      <template v-else>
        <v-alert
          v-if="bad_orientation"
          color="warning"
          icon="$warning"
          :title="$t('exercise_launch.rotate_device')"
          >{{ $t("exercise_launch.rotate_to_landscape") }}</v-alert
        >

        <div v-if="!finished && !show_launch_config">
          <ExerciseV1Mount 
            v-if="mount_exercise && exercise.version == 1"
            ref="MountedExercise"
            :exercise="exercise"
            :config="config"
            :unique_id="unique_id"
            :compat="compat"
            :fullscreen_enabled="fullscreen_enabled"
            @exercise-finished="exercise_finished"
          />
          <ExerciseV2Mount 
            v-else-if="mount_exercise && exercise.version == 2"
            ref="MountedExercise"
            :exercise="exercise"
            :config="config"
            :unique_id="unique_id"
            :compat="compat"
            :fullscreen_enabled="fullscreen_enabled"
            @exercise-finished="exercise_finished"
          />
        </div>
      </template>

      <!-- Exercise Finished Info -->
      <v-container v-if="finished" class="opticalgym-exercise-score">
        <v-layout align-center justify-center>
          <v-flex xs12 md10 align-self-center>
            <v-card flat class="mt-2">
              <v-card-title primary-title>
                <div style="margin: 0px auto">
                  <h3
                    v-if="level_completed && exercise.num_levels"
                    class="headline mb-0"
                    style="color: green; font-weight: strong"
                  >
                    {{ $t("exercise_launch.you_completed_level") }}
                    {{ config.level }}
                  </h3>
                  <h3
                    v-if="level_completed && !exercise.num_levels"
                    class="headline mb-0"
                    style="color: green; font-weight: strong"
                  >
                    {{ $t("common.well_done") }}!
                  </h3>
                  <ExerciseScoreSummary
                    style="margin: 0 auto; max-width: 600px"
                    :score="score"
                    :exercise="exercise"
                    context="exercise-launch"
                    :exercise_config="config"
                  />
                </div>
              </v-card-title>

              <v-layout align-center justify-space-around>
                <v-btn
                  v-if="level_completed && config.level < exercise.num_levels"
                  color="green"
                  @click="next_level()"
                  >{{ $t("common.next_level") }}</v-btn
                >
                <v-btn @click="try_again()">{{ $t("common.try_again") }}</v-btn>
                <v-btn @click="exercise_done()">{{ $t("common.done") }}</v-btn>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- Instructions -->
      <div
        v-if="!bad_screen_size"
        style="overflow: auto; font-size: smaller"
        class="pa-10"
      >
        <v-sheet
          v-if="!finished && custom_instruction"
          elevation="5"
          class="pa-5 ma-5"
        >
          <h3 v-if="custom_instruction_credit">
            Special Instructions from {{ custom_instruction_credit }}
          </h3>

          <span style="white-space: pre-line; font-size: large">{{
            custom_instruction
          }}</span>
        </v-sheet>

        <div
          v-if="!finished"
          style="font-size: larger"
          class="exercise-instructions"
          v-html="exercise_instructions(exercise)"
        />
        <div v-if="exercise.level_hint" style="font-size: larger">
          {{ translated(exercise.level_hint) }}
        </div>
      </div>
    </div>

    <div v-else>
      <v-container
        v-if="!finished && fullscreen_enabled && !show_launch_config"
        fluid
      >
        <v-layout align-center justify-center row fill-height>
          <v-alert type="error">
            {{ $t("exercise_launch.there_was_an_error_loading_the_exercise") }}
            <br />
            <br />
            <p style="text-align: center">
              <a
                href="javascript:window.location.reload();"
                style="color: white; margin-left: -50px"
                >{{ $t("exercise_launch.click_here_to_reload") }}</a
              >
            </p>
          </v-alert>
        </v-layout>
      </v-container>
    </div>

    <!-- Exercise Education -->
    <v-sheet v-if="exercise_education" elevation="5" class="pa-5 ma-5">
      <h3 v-if="exercise_education">
        {{ $t("video_exercise.exercise_education_title") }}
      </h3>
      <div v-html="exercise_education" />
    </v-sheet>
  </div>
</template>

<script>
import OpticalGymConfigForms from "../../../exercises/OpticalGymConfigForms.js";
import ExerciseScoreSummary from "./ExerciseScoreSummary.vue";
import Vue from "vue";
import { mapState } from "vuex";
import Handlebars from "handlebars";
import { Calibration } from "../classes/calibration";
import ExerciseV1Mount from "./ExerciseV1Mount.vue";
import ExerciseV2Mount from "./ExerciseV2Mount.vue";

let uid = 0;

// Map ConfigForms components
let exercise_components = {};
for (let exercise_id of Object.keys(OpticalGymConfigForms)) {
  exercise_components[exercise_id + "-config"] =
    OpticalGymConfigForms[exercise_id];
}

Handlebars.registerHelper("anaglyph_colour", function (aString) {
  // TODO: i18n
  if (aString == "green-red") {
    return "green/red";
  } else if (aString == "red-green") {
    return "red/green";
  } else if (aString == "red-cyan") {
    return "red/blue";
  } else if (aString == "cyan-red") {
    return "blue/red";
  }
});

Handlebars.registerHelper("equals", function (arg1, arg2, options) {
  return arg1 === arg2 ? options.fn(this) : options.inverse(this);
});

Handlebars.registerHelper(
  "hasComprehensionQuestions",
  function (text_uri, options) {
    let fnTrue = options.fn;
    let fnFalse = options.inverse;

    if (text_uri.startsWith("feed:") || text_uri.startsWith("data:")) {
      return fnFalse();
    }
    return fnTrue();
  }
);

export default {
  components: Object.assign({}, exercise_components, {ExerciseV1Mount, ExerciseV2Mount, ExerciseScoreSummary}),
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    custom_instruction: {
      type: String,
      default() {
        return "";
      },
    },
    custom_instruction_credit: {
      type: String,
      default() {
        return "";
      },
    },
    exercise_education: {
      type: String,
      default() {
        return "";
      },
    },
    // Is the exercise fully configured? Do we need to show additional config with has_launch_config?
    fully_configured: {
      type: Boolean,
      default() {
        return false;
      },
    },
    config_version: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  data() {
    return {
      finished: false,
      loading: !this.exercise.has_launch_config || this.fully_configured,
      iframe_loaded: false,
      score: {},
      level_completed: false,
      show_launch_config: !this.fully_configured,
      error_loading_exercise: false,
      fullscreen_enabled: this.compute_fullscreen_enabled(),
      bad_orientation: false,
      bad_screen_size: false,
      mount_exercise: false,
      verification_errors: [],
    };
  },
  computed: {
    ...mapState([
      "current_clinic",
      "user_metadata",
      "current_calibration",
      "app_config",
    ]),
    compat() {
      for (let metadata of this.user_metadata) {
        if (metadata.category == "compat") {
          return metadata.data;
        }
      }
      return false;
    },
  },
  watch: {},
  beforeCreate() {
    uid += 1;
    this.unique_id = "exercise-launch-" + uid.toString();
  },
  mounted() {
    this.check_orienation();
    this.check_screen_size();

    // When orientation changes, recompute bad_orientation
    window.addEventListener("resize", (_event) => {
      this.check_orienation();
    });
  },
  methods: {
    exercise_finished(result) {
      // TODO: Remove getScore and levelPassed from the report
      // and use the result object directly
      if (result.report && result.report.getScore) {
        this.score = result.report.getScore();
      } else {
        this.score = result.score;
      }

      if (result.report && result.report.levelPassed) {
        this.level_completed = result.report.levelPassed();
      } else if (result.level_completed) {
        this.level_completed = true;
      } else {
        this.level_completed = false;
      }

      this.$emit("exercise-finished", result);
      this.finished = true;
      this.clear_exercise();
    },
    clear_exercise() {
      if (this.$refs['MountedExercise'] && this.$refs['MountedExercise'].clear_exercise) {
        this.$refs['MountedExercise'].clear_exercise();
      }
      this.mount_exercise = false;
    },
    compute_fullscreen_enabled() {
      if (this.isIOS()) {
        return false;
      }
      if (window.document.fullscreenEnabled) {
        return true;
      }
      if (window.document.webkitFullscreenEnabled) {
        return true;
      }
      return false;
    },
    exercise_instructions(exercise) {
      let locale = this.$i18n.lang();
      let instructions = exercise.instructions.en;
      if (exercise.instructions[locale]) {
        instructions = exercise.instructions[locale];
      }

      let calibration = this.current_calibration;
      if (!calibration) {
        calibration = new Calibration();
        calibration.setDefaultValues();
      }
      let vals = {
        config: this.config,
        calibration,
        touch_device: this.isTouchDevice,
        dark_mode: this.$vuetify.theme.dark,
      };

      if (!instructions) {
        return "";
      }

      const template = Handlebars.compile(instructions);

      return template(vals);
    },
    // Must be called by parent component
    load: function () {
      this.reset();
      this.show_launch_config = !this.fully_configured;
      if (!this.exercise.has_launch_config || this.fully_configured) {
        this.load_exercise();
      }
    },
    reset() {
      this.finished = false;
      this.loading = !this.exercise.has_launch_config || this.fully_configured;
      this.clear_exercise();
      this.apply_description_visibility();
    },
    load_exercise() {
       this.mount_exercise = false;
       Vue.nextTick(() => {
         this.mount_exercise = true;
       });
    },
    translated(prop) {
      let locale = this.$i18n.lang();
      if (prop[locale]) {
        return prop[locale];
      } else {
        return prop.en;
      }
    },
    apply_description_visibility() {
      // Modify descriptions as per config
      document
        .querySelectorAll(".exercise-instructions .exercise-config-hide")
        .forEach(function (element) {
          element.style.display = "none";
        });
      for (var key in this.config) {
        let value = this.config[key].toString().replace(/[^\w]/gi, "");
        document
          .querySelectorAll(
            ".exercise-instructions .exercise-config-show-" + key + "-" + value
          )
          .forEach(function (element) {
            element.style.display = "inherit";
          });
      }
    },
    try_again: function () {
      this.finished = false;
      if (this.exercise.has_launch_config) {
        this.show_launch_config = true;
      } else {
        Vue.nextTick(() => {
          this.load_exercise();
        });
      }
    },
    next_level: function () {
      if (this.exercise.has_launch_config) {
        this.show_launch_config = true;
      }
      this.$emit("exercise-level", this.config.level + 1);
    },
    exercise_done: function () {
      this.clear_exercise();
      this.$emit("exercise-done");
    },
    launch_exercise_from_config() {
      this.verification_errors = [];
      if (
        this.$refs["exerciseLaunchConfig"] &&
        this.$refs["exerciseLaunchConfig"].validate
      ) {
        let errors = this.$refs["exerciseLaunchConfig"].validate(this.config);
        if (errors && errors.length > 0) {
          this.verification_errors = errors;
          return;
        }
      }

      this.show_launch_config = false;
      Vue.nextTick(() => {
        this.load_exercise();
      });
    },
    isTouchDevice() {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
    },
    isIOS() {
      // Old iOS
      if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
      }

      // Chrome on IOS
      if (navigator.userAgent.match("CriOS")) {
        return true;
      }

      // New iOS
      if (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      ) {
        return true;
      }

      return false;
    },
    check_orienation() {
      // Check Proper API first
      if (screen.orientation && screen.orientation.type) {
        switch (screen.orientation.type) {
          case "portrait-secondary":
          case "portrait-primary":
            this.bad_orientation = true;
            break;
          default:
            this.bad_orientation = false;
        }
        return; // No need to keep checking
      }

      // window.orientation is only used on some mobile browsers, so check it if it's set
      if (window.orientation !== undefined) {
        if (
          (window.orientation == 0 || window.orientation == 180) &&
          window.innerHeight > window.innerWidth
        ) {
          this.bad_orientation = true;
        } else {
          this.bad_orientation = false;
        }
        return; // No need to keep checking
      }

      // Try to do it manually
      if (
        screen.height == window.innerHeight &&
        screen.width == window.innerWidth &&
        window.innerHeight > window.innerWidth
      ) {
        this.bad_orientation = true;
      } else {
        this.bad_orientation = false;
      }
    },

    check_screen_size() {
      if (this.current_clinic && this.current_clinic.has_tag("allow_small_screens")) {
        this.bad_screen_size = false;
      }
      else if (window.screen.height <= 430 || window.screen.width <= 430) {
        this.bad_screen_size = true;
      } else {
        this.bad_screen_size = false;
      }
    },
  },
};
</script>

<style>
.opticalgym-exercise-iframe-fullscreen-disabled {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1000;
}

.opticalgym-exercise-iframe-fullscreen-enabled {
  height: 80px;
}

.exercise-config-hide {
  display: none;
}
</style>
