<template>
  <div>
    <div v-if="exercises && video_exercises && regimen.exercises.length" style="background: none">
      <v-card-title v-if="show_title" style="padding: 0px">
        <v-toolbar grey flat>
          <v-toolbar-title class="regimen_title">
            <span v-if="title">{{ title }}</span>
            <span v-else>{{ $t("common.exercises_for", {name_given: user.name_given, name_family: user.name_family}) }}</span>
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <div class="pa-6">
        <div v-if="regimen_instructions" style="white-space: pre-line; font-size: large">
          {{ regimen_instructions }}
        </div>
        <v-list v-if="regimen.exercises.length">
          <div v-for="(item, index) in regimen.exercises" :key="index" :class="'regimen-item ' + 'regimen-item-' + item.exercise_type">
            <!-- Interactive -->
            <v-card
              v-if="exercises[item.exercise_id] && (item.exercise_type == 'interactive' || !item.exercise_type)"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
              @click="launch_exercise(index)"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <!-- Start Arrow -->
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img :src="start_arrow_href" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <div class="regimen_exercise_icon">
                  <v-icon>{{ app_config.icons.interactive_exercise }}</v-icon>
                </div>

                <div class="regimen_exercise_title">
                  <v-list-item-title>
                    {{ exercise_title(item) }}
                    <v-chip v-if="!calibrated && (exercises[item.exercise_id].requires_calibration || item.config.anaglyph_colour)" small label>{{
                      $t("common.requires_calibration")
                    }}</v-chip>
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.level > 0" style="max-height: 40px">
                    {{ $t("common.level") }} {{ item.level }}
                    <span
                      v-if="
                        (item.automatic_levelling || item.automatic_levelling == undefined) &&
                        exercise_results[index] &&
                        exercise_results[index].level_completed &&
                        item.level != exercise_results[index].level_completed_level
                      "
                      >({{ $t("regimen.level_low") }}
                      {{ exercise_results[index].level_completed_level }}
                      {{ $t("regimen.completed_low") }})</span
                    >

                    <span
                      v-if="
                        (item.automatic_levelling || item.automatic_levelling == undefined) &&
                        exercise_results[index] &&
                        exercise_results[index].level_completed &&
                        item.level == exercise_results[index].level_completed_level
                      "
                      >({{ $t("regimen.max_level_reached") }} )</span
                    >
                  </v-list-item-subtitle>
                </div>
              </div>

              <!-- Feedback -->
              <div class="regimen_exercise_feedback" @click.stop="no_op">
                <v-textarea
                  v-if="!ephemeral && exercise_results[index] && exercise_results[index].id && !clinic.has_tag('nofeedback')"
                  v-model="exercise_results[index].feedback"
                  append-icon="comment"
                  outlined
                  dense
                  rows="2"
                  :label="$t('regimen.feedback_and_comments')"
                  :hide-details="!feedback_focus[index]"
                  :messages="$t('regimen.press_enter_to_save')"
                  :loading="feedback_in_flight[index]"
                  auto-grow
                  no-resize
                  @click.stop="no_op"
                  @focus="feedback_focus[index] = true"
                  @blur="
                    feedback_focus[index] = false;
                    feedback_blurred(index, item);
                  "
                  @keydown.enter.prevent="save_feedback(index, item, 'enter')"
                  @input="schedule_save_feedback(index, item)"
                ></v-textarea>
              </div>

              <!-- Level completed marker -->
              <div v-if="$vuetify.breakpoint.name != 'xs'" class="regimen_exercise_level_completed">
                <div v-if="exercise_results[index] && exercise_results[index].level_completed" style="color: green; font-weight: bold">
                  <div v-if="item.automatic_levelling || item.automatic_levelling == undefined">
                    {{ $t("common.level") }}
                    {{ exercise_results[index].level_completed_level }}
                    {{ $t("regimen.completed") }}!
                  </div>
                  <div v-else>{{ $t("common.well_done") }}</div>
                </div>
              </div>

              <div class="exercise_score_display">
                <ExerciseScoreSummary
                  v-if="exercise_results[index]"
                  context="regimen"
                  :exercise="exercises[item.exercise_id]"
                  :score="exercise_results[index].score"
                  :exercise_config="exercise_results[index].params ? exercise_results[index].params : exercise_results[index].config"
                  dense
                />
              </div>

              <!-- Dialog for launching interactive exercises -->
              <v-dialog
                v-model="exercise_launch_dialog[index]"
                max-width="1000"
                :fullscreen="$vuetify.breakpoint.name == 'xs'"
                :persistent="$vuetify.breakpoint.name == 'xs'"
                @click:outside="exercise_close(index)"
              >
                <v-card v-if="exercise_launch_dialog[index]" style="overflow-x: hidden">
                  <v-toolbar dark color="primary">
                    <v-btn icon @click="exercise_close(index)">
                      <v-icon>close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      {{ exercise_title(item) }}
                      <span v-if="exercises[item.exercise_id].num_levels > 1 && item.config.level">- {{ $t("common.level") }} {{ item.config.level }}</span>
                    </v-toolbar-title>
                    <v-spacer />

                    <v-toolbar-items
                      v-if="item.customization && item.customization != 'no_customization' && item.customization != 'no_customization_no_levelling'"
                    >
                      <v-btn text @click="exercise_customize = true">{{ $t("regimen.customize") }}</v-btn>
                    </v-toolbar-items>
                  </v-toolbar>

                  <v-card-text v-if="exercise_customize">
                    <div>
                      <v-layout row wrap justify-space-around>
                        <v-flex xs12 md11 style="margin-top: 20px">
                          <component
                            :is="item.exercise_id + '-config'"
                            ref="exerciseConfig"
                            v-model="item.config"
                            :show_advanced="item.customization == 'advanced'"
                          />
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap justify-space-around style="margin-top: 20px">
                        <v-flex md1>
                          <v-btn large color="primary" @click="launch_exercise_from_config(index)">{{ $t("common.launch") }}</v-btn>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-card-text>

                  <div v-else>
                    <v-layout row wrap justify-space-around style="margin-top: 20px">
                      <v-flex md4>
                        <v-select
                          v-if="exercises[item.exercise_id].num_levels > 1 && item.customization != 'no_customization_no_levelling'"
                          v-model="item.config.level"
                          :items="level_select(item)"
                          :label="$t('common.level')"
                          outlined
                          @change="custom_level_change(index)"
                        />
                      </v-flex>
                    </v-layout>
                  </div>

                  <div v-if="!exercise_customize">
                    <ExerciseLaunch
                      :ref="exercise_ref(index)"
                      :config="item.config"
                      :exercise="exercises[item.exercise_id]"
                      :custom_instruction="item.instructions"
                      :custom_instruction_credit="clinic.title"
                      :exercise_education="item.exercise_education || ''"
                      :fully_configured="exercise_configured[index]"
                      :config_version="item.config_version || 0"
                      @exercise-finished="exercise_finished(index, $event, item)"
                    />
                  </div>
                </v-card>
              </v-dialog>
            </v-card>

            <!-- Custom -->
            <v-card
              v-else-if="item.exercise_type == 'custom'"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
              @click="launch_exercise(index)"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <!-- Start Arrow -->
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img :src="start_arrow_href" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <!-- Icon -->
                <div class="regimen_exercise_icon">
                  <v-icon v-if="item.default.video">{{ app_config.icons.video_exercise }}</v-icon>
                  <v-icon v-else>{{ app_config.icons.text_exercise }}</v-icon>
                </div>

                <!-- Title -->
                <div class="regimen_exercise_title">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t("common.custom") }}</v-list-item-subtitle>
                </div>
              </div>

              <!-- Feedback -->
              <div class="regimen_exercise_feedback" @click.stop="no_op">
                <v-textarea
                  v-if="!ephemeral && exercise_results[index] && exercise_results[index].id && !clinic.has_tag('nofeedback')"
                  v-model="exercise_results[index].feedback"
                  append-icon="comment"
                  outlined
                  dense
                  rows="2"
                  :label="$t('regimen.feedback_and_comments')"
                  :hide-details="!feedback_focus[index]"
                  :messages="$t('regimen.press_enter_to_save')"
                  :loading="feedback_in_flight[index]"
                  auto-grow
                  no-resize
                  @click.stop="no_op"
                  @focus="feedback_focus[index] = true"
                  @blur="feedback_focus[index] = false"
                  @keydown.enter.prevent="save_feedback(index, item, 'enter')"
                ></v-textarea>
              </div>

              <!-- Level completed marker -->
              <div v-if="$vuetify.breakpoint.name != 'xs'" class="regimen_exercise_level_completed"></div>

              <div class="exercise_score_display">
                <div v-if="exercise_results[index] && exercise_results[index].score && exercise_results[index].score.time_taken_ms">
                  <strong>{{ $t("video_exercise.time_taken") }}:</strong><span> {{ ms_to_minutes_seconds(exercise_results[index].score.time_taken_ms) }}</span>
                </div>
              </div>

              <!-- Dialog for custom exercises -->
              <v-dialog
                v-model="exercise_launch_dialog[index]"
                max-width="1000"
                :fullscreen="$vuetify.breakpoint.name == 'xs'"
                :persistent="$vuetify.breakpoint.name == 'xs'"
                @click:outside="exercise_close(index)"
              >
                <VideoExercise
                  v-if="exercise_launch_dialog[index]"
                  ref="VideoExercise"
                  :exercise="item"
                  :visible="exercise_launch_dialog[index]"
                  :data-index="index"
                  :include_stopwatch="!item.timer || item.timer == 'stopwatch'"
                  @exercise-finished="exercise_finished(index, $event, item)"
                  @close="exercise_close(index)"
                />
              </v-dialog>
            </v-card>

            <!-- Questonnaire -->
            <v-card
              v-else-if="item.exercise_type == 'questionnaire'"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
              @click="launch_exercise(index)"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <!-- Start Arrow -->
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img :src="start_arrow_href" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <!-- Icon -->
                <div class="regimen_exercise_icon">
                  <v-icon>mdi-clipboard-list</v-icon>
                </div>

                <!-- Title -->
                <div class="regimen_exercise_title">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t("common.questionnaire") }}</v-list-item-subtitle>
                </div>
              </div>

              <!-- Level completed marker -->
              <div v-if="$vuetify.breakpoint.name != 'xs'" class="regimen_exercise_level_completed"></div>

              <div class="exercise_score_display">
                <div v-if="exercise_results[index]">
                  {{ $t("regimen.questionnaire_complete") }}
                </div>
              </div>

              <!-- Dialog for questionnaires -->
              <v-dialog
                v-model="exercise_launch_dialog[index]"
                max-width="1000"
                :fullscreen="$vuetify.breakpoint.name == 'xs'"
                :persistent="$vuetify.breakpoint.name == 'xs'"
                @click:outside="exercise_close(index)"
              >
                <QuestionnaireExercise
                  ref="QuestionnaireExercise"
                  :exercise="item"
                  :visible="exercise_launch_dialog[index]"
                  :data-index="index"
                  :default_value="exercise_results[index] && exercise_results[index].score ? exercise_results[index].score : {}"
                  @exercise-finished="submit_question_answer(index, item, $event)"
                  @close="exercise_close(index)"
                />
              </v-dialog>
            </v-card>

            <!-- Question (inline) -->
            <v-card
              v-else-if="item.exercise_type == 'question'"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <!-- Start Arrow -->
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img :src="start_arrow_href" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <!-- Icon -->
                <div class="regimen_exercise_icon">
                  <v-icon>fas fa-question</v-icon>
                </div>

                <!-- Title -->
                <div class="regimen_exercise_title">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </div>

                <!-- Inline Question Render -->
                <div class="regimen_inline_question">
                  <VuetifySurvey
                    v-model="questions_data[item.uuid]"
                    :survey="question_to_survey(item.question)"
                    @change="question_answer_changed(index, item)"
                  />
                </div>

                <v-btn v-if="!questions_auto_submit.includes(item.question.type)" class="ml-6" @click="submit_question_answer(index, item)">Submit</v-btn>
              </div>
            </v-card>

            <!-- Assessment -->
            <v-card
              v-else-if="item.exercise_type == 'assessment'"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
              @click="launch_exercise(index)"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <!-- Start Arrow -->
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img :src="start_arrow_href" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <!-- Icon -->
                <div class="regimen_exercise_icon">
                  <v-icon>fas fa-clipboard-list</v-icon>
                </div>

                <!-- Title -->
                <div class="regimen_exercise_title">
                  <v-list-item-title>{{ assessment_title(item) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ $t("common.assessment") }}</v-list-item-subtitle>
                </div>
              </div>

              <!-- Level completed marker -->
              <div v-if="$vuetify.breakpoint.name != 'xs'" class="regimen_exercise_level_completed"></div>

              <div class="exercise_score_display">
                <div v-if="exercise_results[index]">
                  {{ $t("regimen.assessment_complete") }}
                </div>
              </div>

              <!-- Dialog for assessments -->
              <v-dialog
                v-model="exercise_launch_dialog[index]"
                max-width="1200"
                :fullscreen="$vuetify.breakpoint.name == 'xs'"
                :persistent="$vuetify.breakpoint.name == 'xs'"
                @click:outside="exercise_close(index)"
              >
                <AssessmentExercise
                  ref="AssessmentExercise"
                  :exercise="item"
                  :invite_id="current_invite.id"
                  @close="exercise_close(index)"
                  @exercise-finished="assessment_finished(index, $event, item)"
                />
              </v-dialog>
            </v-card>

            <!-- Header -->
            <div v-else-if="item.exercise_type == 'header'" class="reigmen-header" style="padding: 10px; margin-top: 20px">
              <h1>{{ item.title }}</h1>
              <div v-html="filter_html(item.instructions)" />
            </div>

            <!-- Video Exercise -->
            <v-card
              v-else-if="item.exercise_type.startsWith('video') && video_exercises[item.exercise_id]"
              outlined
              rounded
              class="d-flex flex-wrap justify-space-between regimen_exercise_line"
              style="padding: 10px; align-items: center"
              :ripple="false"
              @click="launch_exercise(index)"
            >
              <!-- Start Arrow, checkmark, icon, title -->
              <div class="d-flex" style="padding: 10px; align-items: center">
                <div v-if="show_start_arrow && next_exercise == index" class="regimen_start_arrow">
                  <v-img src="/assets/start_arrow.png" max-width="40"></v-img>
                </div>

                <!-- Done check mark -->
                <div v-else-if="exercise_results[index]" class="regimen_done_check_mark">
                  <v-icon large color="green">check_circle</v-icon>
                </div>

                <div v-else class="regimen_left_spacer"></div>

                <!-- Icon -->
                <div class="regimen_exercise_icon">
                  <v-icon v-if="video_exercises[item.exercise_id].default.video">{{ app_config.icons.video_exercise }}</v-icon>
                  <v-icon v-else>{{ app_config.icons.text_exercise }}</v-icon>
                </div>

                <!-- Title -->
                <div class="regimen_exercise_title">
                  <v-list-item-title>{{ exercise_title(item) }}</v-list-item-title>
                  <v-list-item-subtitle
                    v-if="
                      item.video_exercise_variant &&
                      video_exercises[item.exercise_id] &&
                      video_exercises[item.exercise_id].variants &&
                      video_exercises[item.exercise_id].variants[item.video_exercise_variant] &&
                      video_exercises[item.exercise_id].variants[item.video_exercise_variant].title
                    "
                    >{{ variant_title(video_exercises[item.exercise_id].variants[item.video_exercise_variant]) }}</v-list-item-subtitle
                  >
                </div>
              </div>

              <!-- Feedback -->
              <div class="regimen_exercise_feedback" @click.stop="no_op">
                <v-textarea
                  v-if="!ephemeral && exercise_results[index] && exercise_results[index].id && !clinic.has_tag('nofeedback')"
                  v-model="exercise_results[index].feedback"
                  append-icon="comment"
                  outlined
                  dense
                  rows="2"
                  :label="$t('regimen.feedback_and_comments')"
                  :hide-details="!feedback_focus[index]"
                  :messages="$t('regimen.press_enter_to_save')"
                  :loading="feedback_in_flight[index]"
                  auto-grow
                  no-resize
                  @click.stop="no_op"
                  @focus="feedback_focus[index] = true"
                  @blur="feedback_focus[index] = false"
                  @keydown.enter.prevent="save_feedback(index, item, 'enter')"
                ></v-textarea>
              </div>

              <!-- Level completed marker -->
              <div v-if="$vuetify.breakpoint.name != 'xs'" class="regimen_exercise_level_completed"></div>

              <!-- Score Display -->
              <div class="exercise_score_display">
                <div v-if="exercise_results[index] && exercise_results[index].score && exercise_results[index].score.time_taken_ms">
                  <strong>{{ $t("video_exercise.time_taken") }}:</strong><span> {{ ms_to_minutes_seconds(exercise_results[index].score.time_taken_ms) }}</span>
                </div>
              </div>

              <!-- Dialog for video exercises -->
              <v-dialog
                v-model="exercise_launch_dialog[index]"
                max-width="1000"
                :fullscreen="$vuetify.breakpoint.name == 'xs'"
                :persistent="$vuetify.breakpoint.name == 'xs'"
                @click:outside="exercise_close(index)"
              >
                <VideoExercise
                  v-if="exercise_launch_dialog[index]"
                  ref="VideoExercise"
                  :exercise="video_exercises[item.exercise_id]"
                  :variant="item.video_exercise_variant || ''"
                  :custom_instruction="item.instructions"
                  :custom_instruction_credit="clinic.title"
                  :exercise_education="item.exercise_education || ''"
                  :visible="exercise_launch_dialog[index]"
                  :data-index="index"
                  :include_stopwatch="!item.timer || item.timer == 'stopwatch'"
                  @exercise-finished="exercise_finished(index, $event, item)"
                  @close="exercise_close(index)"
                />
              </v-dialog>
            </v-card>
          </div>
        </v-list>

        <v-card-title v-if="all_done && show_start_arrow">
          <h2 style="margin-left: 75px; margin-bottom: 40px; color: green">
            {{ $t("regimen.all_done") }}
          </h2>
        </v-card-title>

        <slot v-if="all_done" name="all_done"></slot>
      </div>
    </div>

    <!-- No exercises assigned -->
    <v-card v-if="!in_flight && (!exercises || regimen.exercises.length == 0)">
      <div style="margin-top: 100px">
        <v-container>
          <v-layout align-center justify-space-around>
            <v-sheet elevation="2" style="padding: 50px; width: 800px">
              <h2>{{ $t("regimen.no_exercises") }}</h2>
              <p></p>
              <p>{{ $t("regimen.no_exercises_first_text") }}</p>
              <p>{{ $t("regimen.vision_professional_text") }}</p>

              <YourVisionSpecialist :contact_clinic="clinic" :elevation="0" title="" :show_desc="false" :contact_person="owner" />
            </v-sheet>
          </v-layout>
        </v-container>
      </div>
    </v-card>

    <v-dialog v-model="require_calibration_dialog" max-width="400">
      <v-card v-if="require_calibration_dialog">
        <v-card-title class="headline">{{ $t("common.calibration_is_required") }}</v-card-title>
        <v-card-text>{{ $t("common.before_continue_we_need_to_calibrate_your_device") }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="open_calibration_dialog">{{ $t("common.click_here_to_calibrate_your_device") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="screen_too_small_dialog" max-width="400">
      <v-card v-if="screen_too_small_dialog">
        <v-card-title class="headline">{{ $t("common.screen_too_small") }}</v-card-title>
        <v-card-text>{{ $t("regimen.device_too_small_explanation") }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="screen_too_small_dialog = false">{{ $t("common.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import { mapState } from "vuex";
import ExerciseLaunch from "./ExerciseLaunch";
import ExerciseDemoList from "./ExerciseDemoList";
import VideoExercise from "./VideoExercise";
import AssessmentExercise from "./AssessmentExercise.vue";
import QuestionnaireExercise from "./QuestionnaireExercise";
import YourVisionSpecialist from "./YourVisionSpecialist";
import OpticalGymConfigForms from "../../../exercises/OpticalGymConfigForms.js";
import ExerciseScoreSummary from "./ExerciseScoreSummary";
import VuetifySurvey from "./vuetify-survey/components/VuetifySurvey";
import xss from "xss";
import uuid from "uuid";

// Map ConfigForms and Score components
let exercise_components = {};
for (let exercise_id of Object.keys(OpticalGymConfigForms)) {
  exercise_components[exercise_id + "-config"] = OpticalGymConfigForms[exercise_id];
}

export default {
  name: "Regimen",
  components: Object.assign(exercise_components, {
    AssessmentExercise,
    ExerciseLaunch,
    VideoExercise,
    ExerciseDemoList,
    QuestionnaireExercise,
    VuetifySurvey,
    YourVisionSpecialist,
    ExerciseScoreSummary,
  }),
  props: {
    user: {
      type: Object,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    invite_id: {
      type: String,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
    },
    video_exercises: {
      type: Object,
      required: true,
    },
    regimen_override: {
      type: Object,
      required: false,
      default: () => null,
    },
    title: {
      type: String,
      required: false,
      default: () => null,
    },
    show_start_arrow: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    show_title: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    report_tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    ephemeral: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      start_arrow_href: "/assets/start_arrow." + this.$i18n.lang() + ".png",
      dialog: false,
      in_flight: false,
      regimen: { instructions: "", exercises: [], owner_id: "" },
      no_exercises: false,
      exercise_launch_dialog: [],
      exercise_launch_visible: [],
      exercise_configured: [],
      exercise_customize: false,
      exercise_results: [],
      backend_reports: [],
      feedback_focus: {},
      feedback_in_flight: {},
      owner: null,
      require_calibration_dialog: false,
      screen_too_small_dialog: false,
      questions_data: {},
      questions_reports: {},
      questions_auto_submit: ["mood", "rating", "select", "radio", "checkbox", "switch"],
      feedback_commit_timer: null
    };
  },
  computed: {
    ...mapState([
      "user_metadata_loading",
      "current_calibration",
      "current_progress_reports",
      "masquerade",
      "app_config",
      "user_regimens",
      "current_invite",
      "grid_sets",
    ]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    regimen_instructions() {
      if (this.regimen && this.regimen.instructions) {
        return this.regimen.instructions;
      }
      return "";
    },
    calibrated() {
      if (this.user_metadata_loading) {
        return true;
      }
      if (this.current_calibration) {
        return true;
      }

      return false;
    },
    all_done() {
      let total = this.num_results;
      let done = 0;
      for (var i in this.exercise_results) {
        if (this.exercise_results[i] || (this.regimen.exercises[i] && this.regimen.exercises[i].exercise_type == "header")) {
          done++;
        }
      }
      return done == total;
    },
    num_results() {
      return Object.keys(this.exercise_results).length;
    },
    next_exercise() {
      var index = -1;
      if (this.regimen && this.regimen.exercises && this.regimen.exercises.length) {
        for (var i in this.regimen.exercises) {
          if (!this.exercise_results[i]) {
            let instance = this.regimen.exercises[i];
            if (instance.exercise_type == "header") {
              continue;
            }
            if (instance.exercise_type == "interactive" && !this.exercises[instance.exercise_id]) {
              continue;
            }
            if (instance.exercise_type.startsWith("video") && !this.video_exercises[instance.exercise_id]) {
              continue;
            }
            index = i;
            break;
          }
        }
      }
      return index;
    },
  },
  watch: {
    all_done(val) {
      if (val) {
        // Send "daily_regimen_complete" activity to the backend when we are all done
        if (!this.ephemeral) {
          this.save_activty("daily_regimen_complete", {
            regimen_id: this.regimen.id,
          });
        }

        this.$emit("regimen_complete", {
          results: this.exercise_results,
          reports: this.backend_reports,
        });
      }
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    midnight() {
      const now = new Date();
      const midnightLocal = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const isoMidnightNaive = midnightLocal.toISOString().slice(0, 19);

      return isoMidnightNaive;
    },
    ms_to_minutes_seconds(ms) {
      const minutes = Math.floor(ms / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);
      return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    },
    assessment_title(item) {
      let title = "";

      for (let grid_set of this.grid_sets) {
        if (item.assessment_id == grid_set.id) {
          title = grid_set.title;
          break;
        }
      }
      return title;
    },
    question_to_survey(question) {
      // Deep clone question
      let survey_question = JSON.parse(JSON.stringify(question));
      delete survey_question.title;
      return { items: [survey_question] };
    },
    filter_html(html) {
      return xss(html);
    },
    level_select(item) {
      let levels = [];
      for (let i = 1; i <= this.exercises[item.exercise_id].num_levels; i++) {
        if (item.max_level && i > item.max_level) {
          break;
        }
        levels.push({
          value: i,
          text: this.$t("common.level_list", {level_num: i}),
        });
      }

      return levels;
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }
      let exercise;
      if (item.exercise_type.startsWith("video")) {
        exercise = this.video_exercises[item.exercise_id];
      } else {
        exercise = this.exercises[item.exercise_id];
      }

      if (!exercise) {
        return "";
      }

      if (typeof exercise.title === "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    exercise_ref(index) {
      return "exercise_" + index;
    },
    load() {
      let regimen = null;
      if (this.regimen_override) {
        regimen = JSON.parse(JSON.stringify(this.regimen_override));
      } else {
        for (let user_regimen of this.user_regimens) {
          if (user_regimen.clinic_id == this.clinic.id) {
            regimen = JSON.parse(JSON.stringify(user_regimen));
          }
        }
      }

      if (regimen) {
        // Delete any exercises that don't have an exercise_type, or don't have an ID and are not custom and not header
        // TODO: Determine HOW these badly formed exercises got into the db into the first place
        regimen.exercises = regimen.exercises.filter((exercise) => {
          if (!exercise.exercise_type) {
            return false;
          }
          if (
            exercise.exercise_type != "custom" &&
            exercise.exercise_type != "header" &&
            exercise.exercise_type != "questionnaire" &&
            exercise.exercise_type != "question" &&
            exercise.exercise_type != "assessment" &&
            !exercise.exercise_id
          ) {
            return false;
          }
          return true;
        });

        this.regimen = Object.assign({}, this.regimen, regimen);
      } else {
        this.regimen = { instructions: "", exercises: [], owner_id: "" };
      }

      // If we have any items that are assessments, load grid_sets
      if (this.regimen && this.regimen.exercises) {
        for (let item of this.regimen.exercises) {
          if (item.exercise_type == "assessment") {
            this.$store.dispatch("loadGrids");
            break;
          }
        }
      }

      // Find all exercises that are a question, and copy progress into questions_data
      if (this.current_progress_reports) {
        for (let report of this.current_progress_reports) {
          if (report && report.exercise_type == "question") {
            Vue.set(this.questions_data, report.regimen_instance_id, report.score);
          }
        }
      }

      // Set up the visible launch dialogs and set the level
      for (var i in this.regimen.exercises) {
        let regimen_exercise = this.regimen.exercises[i];
        Vue.set(this.exercise_launch_dialog, i, false);
        Vue.set(this.exercise_launch_visible, i, false);

        if (regimen_exercise.exercise_type == "interactive") {
          Vue.set(this.exercise_configured, i, !this.exercises[regimen_exercise.exercise_id].has_launch_config);
        } else {
          Vue.set(this.exercise_configured, i, false);
        }

        if (regimen_exercise.level) {
          this.regimen.exercises[i].config.level = regimen_exercise.level;
        }

        // Process progress reports
        Vue.set(this.exercise_results, i, null); // default value
        if (this.current_progress_reports) {
          for (let report of this.current_progress_reports) {
            if (report.regimen_instance_id == regimen_exercise.uuid) {
              // Deep copy the report
              let exercise_result = JSON.parse(JSON.stringify(report));
              exercise_result.instance_uuid = report.regimen_instance_id;
              Vue.set(this.exercise_results, i, exercise_result);
              break;
            }
          }
        }
      }
    },

    load_progress(callback = null) {
      if (this.ephemeral || !this.invite_id || this.invite_id == uuid.NIL) {
        if (callback) {
          callback();
        }
        return;
      }

      // Load reports from backend (/invite/<id>/regimen/reports?<start>)
      let invite_reports_url = "/api/invite/" + this.invite_id + "/regimen/reports";
      if (this.app_config.regimen_progress_expires == "midnight") {
        // Get local last local midnight time in UTC
        invite_reports_url += "?start=" + this.midnight();
      }

      axios.get(invite_reports_url).then(
        (response) => {
          let resp = response.data;
          if (resp.status == "success") {
            this.backend_reports = resp.data;
          } else {
            messageBus.$emit("error", response.data.error);
          }
          if (callback) {
            callback();
          }
        },
        (error) => {
          messageBus.$emit("error", error);
          window.OcudigitalLogError(error);
          if (callback) {
            callback();
          }
        }
      );
    },
    launch_exercise(index) {
      let self = this;
      try {
        let exercise = this.regimen.exercises[index];

        if (exercise.exercise_type == "interactive") {
          let exercise_id = exercise.exercise_id;
          this.exercise_customize = false;

          // Check if we're too small to launch the exercises
          // TODO: Just check calibration screen-size cm?
          if (
            !this.clinic.has_tag("allow_small_screens") &&
            (window.screen.height < 500 || window.screen.width < 500) &&
            window.ontouchstart &&
            window.devicePixelRatio >= 2
          ) {
            this.screen_too_small_dialog = true;
            return;
          }

          // Check if calibration is set and if we need it
          var calibration = this.current_calibration;
          if (!calibration && (this.exercises[exercise_id].requires_calibration || exercise.config.anaglyph_colour)) {
            this.require_calibration_dialog = true;
          } else {
            // If there's no launch config, show it directly
            if (this.exercises[exercise_id].has_launch_config) {
              Vue.set(this.exercise_launch_visible, index, false);
            } else {
              Vue.set(this.exercise_launch_visible, index, true);
            }

            Vue.set(this.exercise_launch_dialog, index, true);

            // Load the exercise
            Vue.nextTick(function () {
              self.$refs["exercise_" + index][0].load();
            });
          }
        } else if (exercise.exercise_type == "custom" || exercise.exercise_type.startsWith("video")) {
          Vue.set(this.exercise_launch_dialog, index, true);
          Vue.nextTick(function () {
            if (self.$refs["VideoExercise"]) {
              self.$refs["VideoExercise"].forEach(function (elem) {
                if (elem.$attrs["data-index"] == index) {
                  elem.load();
                }
              });
            }
          });
        } else if (exercise.exercise_type == "questionnaire" || exercise.exercise_type == "question" || exercise.exercise_type == "assessment") {
          Vue.set(this.exercise_launch_dialog, index, true);
        }

        this.$emit("exercise_launched", { index, instance: exercise });
      } catch (e) {
        window.OcudigitalLogError(e);
      }
    },
    launch_exercise_from_config(index) {
      try {
        this.exercise_customize = false;
        Vue.set(this.exercise_configured, index, true);
        Vue.nextTick(() => {
          this.$refs["exercise_" + index][0].load();
          Vue.set(this.exercise_launch_visible, index, true);
        });
      } catch (e) {
        window.OcudigitalLogError(e);
      }
    },
    exercise_close(index) {
      Vue.set(this.exercise_launch_dialog, index, false);
      Vue.set(this.exercise_launch_visible, index, false);
      if (this.regimen.exercises[index].exercise_type == "interactive") {
        Vue.set(this.exercise_configured, index, !this.exercises[this.regimen.exercises[index].exercise_id].has_launch_config);
      } else {
        Vue.set(this.exercise_configured, index, false);
      }

      try {
        if (this.$refs["VideoExercise"]) {
          this.$refs["VideoExercise"].forEach(function (elem) {
            if (elem.$attrs["data-index"] == index) {
              elem.stop();
            }
          });
        }
      } catch (e) {
        window.OcudigitalLogError(e);
      }
    },
    assessment_finished(index, report, instance, callback = null) {
      let exercise_result = {};
      exercise_result.level_completed = false;

      // Calculate score
      const assessment_config = this.app_config.assessments[instance.assessment_id];
      let score = {};

      for (let event of report.events["exercise_finished"]) {
        if (assessment_config.reporting_fields[event.uuid]) {
          let reporting_fields = assessment_config.reporting_fields[event.uuid];
          for (let source_key of Object.keys(reporting_fields)) {
            let key = reporting_fields[source_key].key;
            // let title = reporting_fields[source_key].title;
            let source_score = event.score[source_key];
            score[key] = source_score;
          }
        }
      }
      exercise_result.score = score;
      exercise_result.config = {
        assessment_id: report.assessment_id,
        assessment_instance_id: report.assessment_instance_id,
        assessment_grid: report.assessment_grid,
      };
      exercise_result.exercise_type = "assessment";

      if (instance.uuid) {
        exercise_result.instance_uuid = instance.uuid;
      }
      if (instance.title) {
        exercise_result.instance_title = instance.title;
      }

      // Close the exercise dialog
      this.exercise_close(index);

      // Send report to backend
      let backend_report = {
        exercise_type: "assessment",
        clinic_id: this.clinic.id,
        regimen_id: this.regimen.id && this.regimen.id != uuid.NIL ? this.regimen.id : null,
        regimen_delta: index,
        user_id: this.user.id,
        invite_id: this.invite_id ? this.invite_id : uuid.NIL,
        start_time: report.start_time,
        exercise_id: report.assessment_id,
        level: 0,
        level_completed: false,
        time_played: report.time_played,
        time_paused: 0,
        time_total: report.time_played,
        score: score,
        events: report.events,
        params: exercise_result.config,
        calibration: null,
        tags: ["assessment", "assessment_container"],
      };

      if (instance.uuid) {
        backend_report.regimen_instance_id = instance.uuid;
      } else {
        backend_report.regimen_instance_id = null;
      }

      // Submit the backend report, emit exercise_finished, and invoke the callback
      this.submit_backend_report(index, report, instance, exercise_result, backend_report, callback);
    },
    exercise_finished(index, report, instance, callback = null) {
      let exercise_result = {};
      exercise_result.level_completed = report.level_completed;
      exercise_result.score = report.score;
      exercise_result.config = report.report.params;
      exercise_result.exercise_type = instance.exercise_type;

      if (instance.uuid) {
        exercise_result.instance_uuid = instance.uuid;
      }
      if (instance.title) {
        exercise_result.instance_title = instance.title;
      }

      // Close the exercise dialog
      this.exercise_close(index);

      // If we completed the level, update the level on the backend
      if (report.level_completed && report.report.level) {
        let regimen_exercise = this.regimen.exercises[index];
        let exercise = this.exercises[regimen_exercise.exercise_id];
        if (regimen_exercise.automatic_levelling || regimen_exercise.automatic_levelling == undefined) {
          let current_level = report.report.level;
          let max_level = instance.max_level ? instance.max_level : exercise.num_levels;
          if (current_level < max_level) {
            current_level = report.report.level + 1;
          }
          if (current_level != exercise.level) {
            this.update_level(index, current_level);
          }
        }

        exercise_result.level_completed_level = report.report.level;
      }

      // Send report to backend
      let backend_report = {
        exercise_type: report.type,
        clinic_id: this.clinic.id,
        regimen_id: this.regimen.id && this.regimen.id != uuid.NIL ? this.regimen.id : null,
        regimen_delta: index,
        user_id: this.user.id,
        invite_id: this.invite_id ? this.invite_id : uuid.NIL,
        start_time: report.report.timeStart,
        exercise_id: report.report.exercise_id,
        level: report.report.level || 0,
        level_completed: report.level_completed,
        time_played: report.report.timeToComplete,
        time_paused: report.report.pausedTime,
        time_total: report.report.timeToComplete + report.report.pausedTime,
        score: report.score,
        events: report.report.events,
        params: report.report.params,
        calibration: report.report.calibration,
        tags: this.report_tags,
      };

      if (instance.uuid) {
        backend_report.regimen_instance_id = instance.uuid;
      } else {
        backend_report.regimen_instance_id = null;
      }
      if (instance.title) {
        backend_report.regimen_instance_title = instance.title;
      }

      // Submit the backend report, emit exercise_finished, and invoke the callback
      this.submit_backend_report(index, report, instance, exercise_result, backend_report, callback);
    },

    submit_backend_report(index, report, instance, exercise_result, backend_report, callback = null) {
      if (this.invite_id) {
        axios.post("/api/report", backend_report).then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            exercise_result.id = resp.data;
            backend_report.id = resp.data;

            // Save results with ID
            Vue.set(this.exercise_results, index, exercise_result);
            Vue.set(this.backend_reports, index, backend_report);

            if (!this.ephemeral) {
              this.save_activty("exercise_finished", {
                regimen_id: backend_report.regimen_id,
                exercise_id: backend_report.exercise_id,
                exercise_type: backend_report.exercise_type,
                regimen_instance_id: backend_report.regimen_instance_id,
                report_id: backend_report.id,
              });
            }

            if (callback) {
              callback(index, report, instance, exercise_result, backend_report);
            }

            this.$emit("exercise_finished", {
              index,
              report,
              instance,
              exercise_result,
              backend_report,
            });
          } else if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          }
        });
      } else {
        // No invite-id, so we didn't actually store the backend report
        if (!this.ephemeral) {
          this.save_activty("exercise_finished", {
            regimen_id: backend_report.regimen_id,
            exercise_id: backend_report.exercise_id,
            exercise_type: backend_report.exercise_type,
            regimen_instance_id: backend_report.regimen_instance_id,
            report_id: null,
          });
        }

        if (callback) {
          callback(index, report, instance, exercise_result, backend_report);
        }

        this.$emit("exercise_finished", {
          index,
          report,
          instance,
          exercise_result,
          backend_report,
        });
      }
    },
    update_level(exercise_index, new_level) {
      this.regimen.exercises[exercise_index].level = new_level;
      this.regimen.exercises[exercise_index].config.level = new_level;
      this.$emit("level_updated", {
        exercise_instance: this.regimen.exercises[exercise_index],
        exercise_index,
        new_level,
      });
      if (this.regimen.id && this.regimen.id != uuid.NIL) {
        axios("/api/regimen/" + this.regimen.id + "/level/" + exercise_index, {
          method: "put",
          data: new_level,
          headers: { "Content-Type": "application/json" },
        }).then((response) => {
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          }
        });
      }
    },
    custom_level_change(index, _new_level) {
      try {
        let exercise_id = this.regimen.exercises[index].exercise_id;
        let level = this.regimen.exercises[index].config.level;

        // Override config with level config
        let level_config = this.exercises[exercise_id]["level" + level];
        for (var param in level_config) {
          Vue.set(this.regimen.exercises[index].config, param, level_config[param]);
        }

        Vue.nextTick(() => {
          this.$refs["exercise_" + index][0].load();
          this.$forceUpdate();
        });
      } catch (e) {
        window.OcudigitalLogError(e);
      }
    },
    open_calibration_dialog() {
      this.$store.commit("setCalibrationDialog", true);
      this.require_calibration_dialog = false;
    },
    no_op() {
      return false;
    },
    feedback_blurred(index, item) {
      // Submit feedback on blur
      this.save_feedback(index, item, 'blur');
    },
    save_activty(activity_type, details) {
      let activity = {
        user_id: this.user.id,
        clinic_id: this.clinic.id,
        invite_id: this.invite_id ? this.invite_id : uuid.NIL,
        activity: activity_type,
        details: details,
      };
      axios.post("/api/activity", activity).then(function (response) {
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    schedule_save_feedback(index, item) {
      clearTimeout(this.feedback_commit_timer);
      this.feedback_commit_timer = setTimeout(() => {
        this.save_feedback(index, item, 'timeout');
      }, 1500);
    },
    save_feedback(index, _item, trigger = 'enter') {
      clearTimeout(this.feedback_commit_timer);
      try {
        let result = this.exercise_results[index];

        if (trigger !== 'enter' && !result.feedback.trim())  {
          // If feedback is empty, don't save unless it's triggered by enter keypress
          return;
        }

        Vue.set(this.feedback_in_flight, index, true);

        axios.get("/api/report/" + result.id).then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            let report = resp.data;

            if (result.feedback == report.feedback) {
              Vue.set(this.feedback_in_flight, index, false);
              return;
            }

            report.feedback = result.feedback;

            axios.put("/api/report/" + result.id, report).then((response) => {
              let resp = response.data;
              Vue.set(this.feedback_in_flight, index, false);
              if (resp.status == "success") {
                messageBus.$emit("success", this.$t("regimen.feedback_saved"));
              } else if (resp.status == "error") {
                messageBus.$emit("api-error", resp);
              }
            });
          } else if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          }
        });

        return false;
      } catch (e) {
        window.OcudigitalLogError(e);
      }
    },
    question_answer_changed(index, item) {
      if (this.questions_auto_submit.includes(item.question.type)) {
        Vue.nextTick(() => {
          this.submit_question_answer(index, item);
        });
      }
    },
    submit_question_answer(index, item, value) {
      if (item.exercise_type == "question") {
        value = this.questions_data[item.uuid];
      } else {
        value = value.score;
      }
      let existing_report = this.questions_reports[item.uuid];

      if (existing_report) {
        let backend_report = existing_report.backend_report;
        backend_report.score = value;
        backend_report.timeToComplete = Date.now() - backend_report.timeStart;
        axios.put("/api/report/" + backend_report.id, backend_report).then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            if (item.exercise_type == "question") {
              messageBus.$emit("success", this.$t("regimen.question_response_saved"));
            }
            this.exercise_close(index);
          } else if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          }
        });
      } else {
        let report = {
          level_completed: false,
          type: item.exercise_type,
          score: value,
          report: {
            timeStart: Date.now(),
            exercise_id: item.exercise_type,
            level: 0,
            timeToComplete: 0, //ms
            pausedTime: 0,
            events: [],
            params: item.exercise_type == "question" ? item.question : item.questionnaire,
            calibration: {},
          },
        };
        this.exercise_finished(index, report, item, (index, report, instance, exercise_result, backend_report) => {
          this.questions_reports[item.uuid] = {
            index,
            report,
            instance,
            exercise_result,
            backend_report,
          };
          if (item.exercise_type == "question") {
            messageBus.$emit("success", this.$t("regimen.question_response_saved"));
          }
        });
      }
    },
  },
};
</script>

<style>
.exercise_score_display {
  width: 300px;
  margin-left: 13px;
}

.regimen_exercise_line {
  min-height: 80px;
  margin-bottom: 10px;
}

.regimen_start_arrow {
  margin-right: 10px;
}

.regimen_done_check_mark {
  margin-right: 13px;
}

.regimen_left_spacer {
  width: 50px;
}

.regimen_exercise_icon {
  margin-right: 20px;
}

.regimen_exercise_title {
  width: 370px;
}

.regimen_exercise_feedback {
  width: 330px;
  padding-left: 10px;
}

.regimen_exercise_level_completed {
  width: 100px;
  text-align: center;
}

.regimen_inline_question {
  min-width: 400px;
}
</style>
