import { url } from "inspector";
import Cookies from "js-cookie";
import { parseColour } from "./colour";

export enum AnaglyphOrientation {
  RedCyan = "red-cyan",
  CyanRed = "cyan-red",
  RedGreen = "red-green",
  GreenRed = "green-red",
}

export interface ColourCalibration {
  red_brightness: number;
  blue_brightness: number;
  green_brightness: number;
}

export interface Calibration {
  width_px: number;
  height_px: number;
  pixel_ratio: number;
  height_cm: number;
  distance_cm: number;
  anaglyph_orientation: AnaglyphOrientation;
  colour_correction: ColourCalibration;
}

export interface CalibrationOverride {
  width_px?: number;
  height_px?: number;
  pixel_ratio?: number;
  height_cm?: number;
  distance_cm?: number;
  anaglyph_orientation?: AnaglyphOrientation;
  colour_correction?: ColourCalibration;
}

export enum Unit {
  Pixel = "px",
  Centimeter = "cm",
  PrismDiopter = "pdpt",
  Arcsecond = "arcsecond",
}

export interface Offset {
  value: number;
  unit: Unit;
}

export enum EyeSide {
  OD = "od", // Right eye
  OS = "os", // Left eye
}

export default class ExerciseScreen {
  calibration_is_set: boolean;
  calibration: Calibration;

  // Option Overrides
  working_width_px: number | null = null;
  working_height_px: number | null = null;

  constructor(override: CalibrationOverride | null = null) {
    const cookie_calibration = ExerciseScreen.getCalibration();

    if (cookie_calibration) {
      this.calibration_is_set = true;
      this.calibration = cookie_calibration;
    } else {
      this.calibration_is_set = false;
      this.calibration = ExerciseScreen.guessCalibration();
    }

    // Override the calibration with any options passed in
    if (override) {
      if (override.width_px) {
        this.calibration.width_px = override.width_px;
      }
      if (override.height_px) {
        this.calibration.height_px = override.height_px;
      }
      if (override.pixel_ratio) {
        this.calibration.pixel_ratio = override.pixel_ratio;
      }
      if (override.height_cm) {
        this.calibration.height_cm = override.height_cm;
      }
      if (override.distance_cm) {
        this.calibration.distance_cm = override.distance_cm;
      }
      if (override.anaglyph_orientation) {
        this.calibration.anaglyph_orientation = override.anaglyph_orientation;
      }
      if (override.colour_correction) {
        this.calibration.colour_correction = override.colour_correction;
      }
    }

    // If the passed in calibration override was "complete", then mark calibration as set
    if (override && override.width_px && override.height_px && override.pixel_ratio && override.height_cm && override.distance_cm && override.anaglyph_orientation && override.colour_correction) {
      this.calibration_is_set = true;
    }
  }

  // Get the device calibration stored in the cookie.
  // The cookie is keyed by screen-size so that plugging a monitor in will require recalibration.
  static getCalibration(): Calibration | null {
    let calibration_json = Cookies.get("opticalgym_calibration_v2_" + screen.width + "-" + screen.height);

    let calibration: Calibration | null = calibration_json ? JSON.parse(calibration_json) : null;
    if (calibration) {
      calibration = ExerciseScreen.override_from_query_params(calibration);
    }
    return calibration;
  }

  static guessCalibration(): Calibration {
    let width_px = ExerciseScreen.screen_width();
    let height_px = ExerciseScreen.screen_height();

    const height_cm_guess = Math.round(height_px / (28 * window.devicePixelRatio));
    let calibration: Calibration = {
      width_px: width_px,
      height_px: height_px,
      pixel_ratio: window.devicePixelRatio,
      height_cm: height_cm_guess,
      distance_cm: height_cm_guess * 2.5,
      anaglyph_orientation: AnaglyphOrientation.CyanRed,
      colour_correction: {
        red_brightness: 0.75,
        blue_brightness: 0.75,
        green_brightness: 0.75,
      },
    };
    calibration = ExerciseScreen.override_from_query_params(calibration);
    return calibration;
  }

  static override_from_query_params(calibration: Calibration) {
    // Override calibration from query params if specified.
    // Calibration can be overriden by passing in `calibration_override_<prop>`
    // For example '?calibration_override_distance_cm=500`
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("calibration_override_width_px")) {
      calibration.width_px = parseInt(urlParams.get("calibration_override_width_px")!);
    }
    if (urlParams.has("calibration_override_height_px")) {
      calibration.height_px = parseInt(urlParams.get("calibration_override_height_px")!);
    }
    if (urlParams.has("calibration_override_pixel_ratio")) {
      calibration.pixel_ratio = parseFloat(urlParams.get("calibration_override_pixel_ratio")!);
    }
    if (urlParams.has("calibration_override_height_cm")) {
      calibration.height_cm = parseFloat(urlParams.get("calibration_override_height_cm")!);
    }
    if (urlParams.has("calibration_override_distance_cm")) {
      calibration.distance_cm = parseFloat(urlParams.get("calibration_override_distance_cm")!);
    }
    if (urlParams.has("calibration_override_anaglyph_orientation")) {
      calibration.anaglyph_orientation = urlParams.get("calibration_override_anaglyph_orientation") as AnaglyphOrientation;
    }
    if (urlParams.has("calibration_override_red_brightness")) {
      calibration.colour_correction.red_brightness = parseFloat(urlParams.get("calibration_override_red_brightness")!);
    }
    if (urlParams.has("calibration_override_blue_brightness")) {
      calibration.colour_correction.blue_brightness = parseFloat(urlParams.get("calibration_override_blue_brightness")!);
    }
    if (urlParams.has("calibration_override_green_brightness")) {
      calibration.colour_correction.green_brightness = parseFloat(urlParams.get("calibration_override_green_brightness")!);
    }

    return calibration;
  }

  // Set the device calibration - saved into a cookie.
  // The cookie is keyed by screen-size so that plugging a monitor in will require recalibration.
  setCalibration() {
    let calibration_json = JSON.stringify(this.calibration);
    Cookies.set("opticalgym_calibration_v2_" + this.calibration.width_px + "-" + this.calibration.height_px, calibration_json, { expires: 365 });
  }

  // Some stupid devices (iOS cough cough) don't have width and height
  // correctly defined when they are in landscape mode.
  // So always use the largest as width and the smallest as height.
  // We enforce portait mode, so this should be fine.
  static screen_width(): number {
    return Math.max(screen.width, screen.height);
  }

  // TODO: Take into account devices that cannot go fullscreen.
  static screen_height(): number {
    return Math.min(screen.width, screen.height);
  }

  // Set the "working" width in px, which could be different from the screen width in px
  set_working_width_px(width_px: number) {
    this.working_width_px = width_px;
  }

  // Set the "working" width in px, which could be different from the screen width in px
  set_working_height_px(height_px: number) {
    this.working_height_px = height_px;
  }

  get width(): number {
    if (this.working_width_px) {
      return this.working_width_px;
    } else {
      return this.calibration.width_px;
    }
  }

  get height(): number {
    if (this.working_height_px) {
      return this.working_height_px;
    } else {
      return this.calibration.height_px;
    }
  }

  /**
   * Given an offset string, parse it, returning both the value and the unit
   */
  static parseOffset(offset_string: string | number, default_unit: Unit | null = null): Offset {
    if (typeof offset_string == "number") {
      return {
        value: offset_string,
        unit: default_unit || Unit.Pixel,
      };
    }

    offset_string = offset_string.trim();

    let rx = /(-?\d*\.?\d*)\s*(.*)/;
    let match = rx.exec(offset_string);
    if (!match || !match[0] || !match[1]) {
      throw "Unable to parse " + offset_string;
    }
    let value = +match[1];
    let unit;

    // Trim any trailing 's' off the unit
    var raw_unit = match[2].replace(/s$/, "");

    // parse unit
    if (!raw_unit) {
      raw_unit = default_unit || Unit.Pixel;
    }
    if (["px", "pixel", "píxel", "פיקסל", "بكسل", "بيكسل"].includes(raw_unit)) {
      unit = Unit.Pixel;
    } else if (
      ["cm", "centimeter", "centímetro", "centimetro", "centimètre", "centímetro", "centimetre", "סנטימטר", 'ס"מ', "سنتيمتر", "سم"].includes(raw_unit)
    ) {
      unit = Unit.Centimeter;
    } else if (["mm", "millimeter", "millimètre", "milímetro", "milimetro", "מילימטר", 'מ"מ', "مليمتر", "مم"].includes(raw_unit)) {
      unit = Unit.Centimeter;
      value = value / 10;
    } else if (
      [
        "Δ",
        "prism diopter",
        "prism",
        "prisme",
        "prism-diopter",
        "dioptría de prisma",
        "dioptria de prisma",
        "dioptría prismática",
        "dioptria prismática",
        "dioptria prismatica",
        "dioptria de prisma",
        "דיופטרת פריזמטית",
        "דיופטריה",
        "פריזמה",
        "ديوبتر منشوري",
        "منشور",
        "ديوبتر",
        "درجة منشورية",
        "dioptria",
        "dioptría",
        "prisma",
        "prisme",
        "pdpt",
        "prdpt",
        "PD",
        "pd",
      ].includes(raw_unit)
    ) {
      unit = Unit.PrismDiopter;
    } else if (
      [
        "arcsecond",
        "seconde d'arc",
        "arc sec",
        "segundo de arco",
        "arcosegundo",
        "arc second",
        "arcsec",
        "arcseg",
        "asec",
        "as",
        "″",
        '"',
        "''",
        "second",
        "seconde",
        "segundo",
        "segunda",
        "שניית קשת",
        "seg de arco",
        "ثانية قوسية",
        "ثانية",
        "ث",
        "שנ׳", // Hebrew abbreviation for שנייה, used for arcseconds
        "ثا", // Arabic shorthand in some scientific texts for arcseconds
      ].includes(raw_unit)
    ) {
      unit = Unit.Arcsecond;
    } else if (
      [
        "arcminute",
        "arcmin",
        "amin",
        "am",
        "′",
        "'",
        "minute d'arc",
        "minuto de arco",
        "דקת קשת",
        "دقيقة قوسية",
        "دقيقة",
        "د",
        "ד׳", // Hebrew abbreviation for דקה used for arcminutes
        "دق", // Arabic short form for دقيقة قوسية (seen in educational texts)
      ].includes(raw_unit)
    ) {
      unit = Unit.Arcsecond;
      value = value * 60;
    } else if (
      [
        "degree",
        "degrees",
        "deg",
        "degs",
        "°",
        "degré",
        "degré d'arc",
        "מעלה",
        "grado",
        "grados",
        "grau",
        "graus",
        "grau de arco",
        "grado de arco",
        "درجة",
        "°ق",
        "درجات",
        "درجه",
        "מ׳", // Hebrew shorthand for מעלה, sometimes used
        "°م", // Arabic shorthand variant for "degree" (not common but used)
      ].includes(raw_unit)
    ) {
      unit = Unit.Arcsecond;
      value = value * 3600;
    } else {
      throw "Unable to parse " + offset_string;
    }

    return {
      value: value,
      unit: unit,
    };
  }

  /**
   * Check calibration
   */
  checkCalibration() {
    var err = "Your screen calibration is out of date or you changed devices.";

    if (this.calibration.height_px != ExerciseScreen.screen_height()) {
      throw err;
    }
    if (this.calibration.width_px != ExerciseScreen.screen_width()) {
      throw err;
    }
    if (this.calibration.pixel_ratio != window.devicePixelRatio) {
      throw err;
    }
  }

  /**
   * Get the distance from the screen in cm
   */
  distance_cm(): number {
    return this.calibration.distance_cm;
  }

  /**
   * Pixels per centimeter on the screen
   */
  pixelsFromCentimeters(cm: number): number {
    return cm * (this.height / this.calibration.height_cm);
  }

  /**
   * Pixels per arcsecond
   */
  pixelsFromArcseconds(asec: number): number {
    let cm = this.centimetersFromArcseconds(asec);
    return this.pixelsFromCentimeters(cm);
  }

  /**
   * Pixels per degrees
   */
  pixelsFromDegrees(deg: number): number {
    let cm = this.centimetersFromArcseconds(deg * 3600);
    return this.pixelsFromCentimeters(cm);
  }

  /**
   * Pixels per centimeter on the screen
   */
  centimetersFromPixels(px: number): number {
    return px / (this.height / this.calibration.height_cm);
  }

  /**
   * Centimeters per arcsecond
   */
  centimetersFromArcseconds(arcs: number): number {
    if (arcs > 648000) {
      throw "Cannot caculate centimeters per arcseconds for angles over 180 degrees.";
    }

    // Focal distance in centimeters
    var f = this.distance_cm();

    // Radians per arcsecond (206265 arcseconds in a radian)
    var rads = arcs / 206265;

    // given apex angle, calculate opposite edge length for an isosceles triangle
    var alpha = (Math.PI - rads) / 2; // Calculate base angles
    var side = f / Math.sin(alpha); // Calculate length of sides
    var base = 2 * side * Math.cos(alpha); // Calculate opposite edge / base (in meters)

    // Return as cm
    return base;
  }

  /**
   * Centimeters from prism diopters
   */
  centimetersFromPrismDiopters(prisms: number): number {
    let asec = this.arcsecondsFromPrismDiopters(prisms);
    return this.centimetersFromArcseconds(asec);
  }

  /**
   * Arcseconds per centimeter
   */
  arcsecondsFromCentimeters(cm: number): number {
    // Focal distance in meters
    var f = this.distance_cm();

    // Formula: θ = atan(2h/a) (this gets the base angle)
    var θ = Math.atan((2 * f) / cm);

    // A triangle has PI radian angles (180 degrees)
    var apex = Math.PI - 2 * θ;

    // Convert to arcseconds (206265 arcseconds in a radian)
    var arcs = apex * 206265;

    return arcs;
  }

  /**
   * Arcseconds from a number of pixels on the screen
   */
  arcsecondsFromPixels(px: number): number {
    let cm = this.centimetersFromPixels(px);
    return this.arcsecondsFromCentimeters(cm);
  }

  /**
   * Degrees of arc from pixels on the screen
   */
  degreesFromPixels(px: number): number {
    let asec = this.arcsecondsFromPixels(px);
    return asec / 3600;
  }

  /**
   * Get the number of arcseconds in the given number of prism dioperes
   *
   * See: https://www.wolframalpha.com/input/?i=1+prism+diopter+in+arcsecond
   */
  arcsecondsFromPrismDiopters(pdpt: number): number {
    // Simplified from:
    // var rads = 2 * Math.atan(0.5 * (0.01 / 1))  // ==> 0.01
    // var degs = rads * 180 / Math.PI             // ==> 0.572953020554149;
    // var arcs = degs * 3600;                     // ==> 2062.630872
    var arcs = 2062.630872;
    return pdpt * arcs;
  }

  /**
   * Get the number of prism dioperes per arcsecond
   */
  prismDioptersFromCentimeters(cm: number): number {
    var asec = this.arcsecondsFromCentimeters(cm);
    return this.prismDioptersFromArcseconds(asec);
  }

  /**
   * Get the number of prism dioperes per arcsecond
   */
  prismDioptersFromArcseconds(arcs: number): number {
    var pdpt = 1 / 2062.630872;
    return pdpt * arcs;
  }

  /**
   * Get total pixel offset from prism diopters
   *
   * If you are offsetting left and right from a centerpoint, divide result by two
   */
  pixelsFromPrismDiopters(pdpt: number): number {
    var arcs = this.arcsecondsFromPrismDiopters(pdpt);
    return this.pixelsFromArcseconds(arcs);
  }

  /**
   * Given an offset string, parse it, returning number of pixels
   */
  offsetToPixels(offset_string: string, default_unit: Unit | null = null): number {
    var offset = ExerciseScreen.parseOffset(offset_string, default_unit);
    return this.pixelsFromOffset(offset);
  }

  /**
   * Given an offset numeric value, parse it given it's unit, returning number of pixels
   */
  pixelsFromOffset(offset: Offset): number {
    switch (offset.unit) {
      case Unit.Pixel:
        return offset.value;
      case Unit.Arcsecond:
        return this.pixelsFromArcseconds(offset.value);
      case Unit.PrismDiopter:
        return this.pixelsFromPrismDiopters(offset.value);
      case Unit.Centimeter:
        return this.pixelsFromCentimeters(offset.value);
      default:
        throw "Unknown unit: " + offset.unit;
    }
  }

  /**
   * Given an offset string, parse it, returning number of cm
   */
  parseOffsetToCentimeters(offset_string: string): number {
    var offset = ExerciseScreen.parseOffset(offset_string);

    switch (offset.unit) {
      case Unit.Pixel:
        return this.centimetersFromPixels(offset.value);
      case Unit.Arcsecond:
        return this.centimetersFromArcseconds(offset.value);
      case Unit.PrismDiopter:
        return this.centimetersFromPrismDiopters(offset.value);
      case Unit.Centimeter:
        return offset.value;
      default:
        throw "Unknown unit: " + offset.unit;
    }
  }

  /**
   * Given an offset string, parse it, returning number of prisms
   */
  parseOffsetToPrisms(offset_string: string): number {
    let offset = ExerciseScreen.parseOffset(offset_string);

    switch (offset.unit) {
      case Unit.Pixel:
        const centimeters = this.centimetersFromPixels(offset.value);
        const arcseconds = this.arcsecondsFromCentimeters(centimeters);
        return this.prismDioptersFromArcseconds(arcseconds);
      case Unit.Arcsecond:
        return this.prismDioptersFromArcseconds(offset.value);
      case Unit.PrismDiopter:
        return offset.value;
      case Unit.Centimeter:
        const arcseconds_2 = this.arcsecondsFromCentimeters(offset.value);
        return this.prismDioptersFromArcseconds(arcseconds_2);
      default:
        throw "Unknown unit: " + offset.unit;
    }
  }

  /**
   * Given an offset string, parse it, returning number of arcseconds
   */
  parseOffsetToArcseconds(offset_string: string): number {
    let offset = ExerciseScreen.parseOffset(offset_string);

    switch (offset.unit) {
      case Unit.Pixel:
        const centimeters = this.centimetersFromPixels(offset.value);
        return this.arcsecondsFromCentimeters(centimeters);
      case Unit.Arcsecond:
        return offset.value;
      case Unit.PrismDiopter:
        return this.arcsecondsFromPrismDiopters(offset.value);
      case Unit.Centimeter:
        return this.arcsecondsFromCentimeters(offset.value);
      default:
        throw "Unknown unit: " + offset.unit;
    }
  }

  /**
   * The the anaglyph colour for a single eye.  Side is specified as 'od' or 'os'.
   */
  anaglyphColour(side: EyeSide, return_as_int = false, cancellation_level = 1): string | number {
    if (
      (side == "od" && this.calibration.anaglyph_orientation == "cyan-red") ||
      (side == "os" && this.calibration.anaglyph_orientation == "red-cyan") ||
      (side == "od" && this.calibration.anaglyph_orientation == "green-red") ||
      (side == "os" && this.calibration.anaglyph_orientation == "red-green")
    ) {
      let red_bright = this.calibration.colour_correction.red_brightness || 1;
      if (cancellation_level != 1) {
        red_bright = red_bright + (1 - red_bright) * (1 - cancellation_level);
      }
      return parseColour("#FF0000", return_as_int, red_bright);
    } else if ((side == "od" && this.calibration.anaglyph_orientation == "red-cyan") || (side == "os" && this.calibration.anaglyph_orientation == "cyan-red")) {
      let blue_bright = this.calibration.colour_correction.blue_brightness || 1;
      if (cancellation_level != 1) {
        blue_bright = blue_bright + (1 - blue_bright) * (1 - cancellation_level);
      }
      return parseColour("#0000FF", return_as_int, blue_bright);
    } else if (
      (side == "od" && this.calibration.anaglyph_orientation == "red-green") ||
      (side == "os" && this.calibration.anaglyph_orientation == "green-red")
    ) {
      let green_bright = this.calibration.colour_correction.green_brightness || 1;
      if (cancellation_level != 1) {
        green_bright = green_bright + (1 - green_bright) * (1 - cancellation_level);
      }
      return parseColour("#00FF00", return_as_int, green_bright);
    } else {
      throw "singleAnaglyphColour: invalid side: " + side + " (orientation: " + this.calibration.anaglyph_orientation + ")";
    }
  }

  /**
   * Get an array of anaglyph colours, suitable to choosing a random colour
   */
  anaglyphColoursArray(return_as_int = false, include_combined = false, cancellation_level = 1, white_background = false): number[] | string[] {
    let red_bright = this.calibration.colour_correction.red_brightness || 1;
    let blue_bright = this.calibration.colour_correction.blue_brightness || 1;
    let green_bright = this.calibration.colour_correction.green_brightness || 1;

    if (cancellation_level != 1) {
      red_bright = red_bright + (1 - red_bright) * (1 - cancellation_level);
      blue_bright = blue_bright + (1 - blue_bright) * (1 - cancellation_level);
      green_bright = green_bright + (1 - green_bright) * (1 - cancellation_level);
    }

    let red, blue, green;
    if (white_background) {
      red = parseColour("#FF0000", return_as_int, 1, 1 - red_bright);
      blue = parseColour("#0000FF", return_as_int, 1, 1 - blue_bright);
      green = parseColour("#00FF00", return_as_int, 1, 1 - green_bright);
    } else {
      red = parseColour("#FF0000", return_as_int, red_bright);
      blue = parseColour("#0000FF", return_as_int, blue_bright);
      green = parseColour("#00FF00", return_as_int, green_bright);
    }

    let colours = [red];
    if (this.calibration.anaglyph_orientation == "red-cyan" || this.calibration.anaglyph_orientation == "cyan-red") {
      colours.push(blue);
    }
    if (this.calibration.anaglyph_orientation == "red-green" || this.calibration.anaglyph_orientation == "green-red") {
      colours.push(green);
    }
    if (include_combined) {
      colours.push(this.combinedAnalyphColour(return_as_int, cancellation_level));
    }

    return colours as number[] | string[];
  }

  /**
   * Get the colour that is the combination of the two configured anaglyph colours.
   * red-cyan => magenta
   * red-green => yellow
   */
  combinedAnalyphColour(return_as_int = false, cancellation_level = 1, white_background = false): string | number {
    let red_bright = this.calibration.colour_correction.red_brightness || 1;
    let blue_bright = this.calibration.colour_correction.blue_brightness || 1;
    let green_bright = this.calibration.colour_correction.green_brightness || 1;

    if (cancellation_level != 1) {
      red_bright = red_bright + (1 - red_bright) * (1 - cancellation_level);
      blue_bright = blue_bright + (1 - blue_bright) * (1 - cancellation_level);
      green_bright = green_bright + (1 - green_bright) * (1 - cancellation_level);
    }

    let magenta_bright = (red_bright + blue_bright) / 2;
    let yellow_bright = (red_bright + green_bright) / 2;

    var magenta, yellow;
    if (white_background) {
      magenta = parseColour("#FF00FF", return_as_int, 1, 1 - magenta_bright);
      yellow = parseColour("#FFFF00", return_as_int, 1, 1 - yellow_bright);
    } else {
      magenta = parseColour("#FF00FF", return_as_int, magenta_bright);
      yellow = parseColour("#FFFF00", return_as_int, yellow_bright);
    }

    if (this.calibration.anaglyph_orientation == "red-cyan" || this.calibration.anaglyph_orientation == "cyan-red") {
      return magenta;
    }
    if (this.calibration.anaglyph_orientation == "red-green" || this.calibration.anaglyph_orientation == "green-red") {
      return yellow;
    }

    throw "combinedAnalyphColour: invalid orientation: " + this.calibration.anaglyph_orientation;
  }
}
