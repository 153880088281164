<template>
  <v-container fluid grid-list-lg class="distance-selector-wrapper">
    <v-layout row wrap>
      <v-flex xs4>
        <v-text-field
          v-model="input_value"
          class="mt-0"
          type="number"
          :suffix="$t('common.cm')"
          :dense="dense"
          @blur="commit"
          @keydown.enter="commit"
          @input="schedule_commit"
        />
      </v-flex>
      <v-flex xs4 offset-xs4>
        <v-text-field
          v-model="input_inches"
          class="mt-0"
          tabindex="-1"
          :suffix="$t('common.inches')"
          :dense="dense"
          @blur="commit_inches"
          @keydown.enter="commit_inches"
          @input="schedule_commit_inches"
        />
      </v-flex>
    </v-layout>
    <v-flex xs12>
      <v-slider
        v-model="localValue"
        :min="min"
        :max="max"
        step="0.5"
        :style="dense ? 'margin-top: -10px': 'margin-top: 20px'"
        @change="$emit('change', value)"
      >
        <template v-slot:prepend>
          <v-icon @click="decrement">mdi-minus</v-icon>
        </template>

        <template v-slot:append>
          <v-icon @click="increment">mdi-plus</v-icon>
        </template>
      </v-slider>
    </v-flex>
  </v-container>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 5000,
    },
    value: {
      type: Number,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input_value: this.value,
      input_inches: this.cm_to_inches(this.value),
      commit_timer: null,
      commit_inches_timer: null,
    };
  },
  computed: {
    inches_display: function () {
      return this.cm_to_inches(this.value);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  watch: {
    value(new_val) {
      this.input_value = new_val;
      this.input_inches = this.cm_to_inches(new_val);
    },
  },
  methods: {
    schedule_commit() {
      clearTimeout(this.commit_timer);
      this.commit_timer = setTimeout(this.commit, 1500);
    },
    commit() {
      clearTimeout(this.commit_timer);
      const parsed = parseFloat(this.input_value);
      if (!isNaN(parsed) && parsed !== this.value) {
        this.$emit('input', parsed);
        this.$emit('change', parsed);
      }
    },
    schedule_commit_inches() {
      clearTimeout(this.commit_inches_timer);
      this.commit_inches_timer = setTimeout(this.commit_inches, 1500);
    },
    commit_inches() {
      clearTimeout(this.commit_inches_timer);
      const parsed = this.parseInches(this.input_inches);
      const cm = this.inches_to_cm(parsed);
      if (!isNaN(cm) && cm !== this.value) {
        this.$emit('input', cm);
        this.$emit('change', cm);
      }
    },
    increment() {
      if (this.localValue != this.max) {
        if (this.localValue % 1 == 0) {
          this.localValue += 1;
        } else {
          this.localValue += 0.5;
        }
      }
    },
    decrement() {
      if (this.localValue != this.min) {
        if (this.localValue % 1 == 0) {
          this.localValue -= 1;
        } else {
          this.localValue -= 0.5;
        }
      }
    },

    // Translate cm to inches, rounded to the nearest 1/4
    cm_to_inches: function (cm) {
      var decimal_inches = cm / 2.54;
      var integer = Math.floor(decimal_inches);
      var mantissa = decimal_inches % 1;

      if (mantissa <= 0.125) {
        mantissa = 0;
      } else if (mantissa <= 0.375) {
        mantissa = "¼";
      } else if (mantissa <= 0.625) {
        mantissa = "½";
      } else if (mantissa <= 0.875) {
        mantissa = "¾";
      } else {
        integer += 1;
        mantissa = 0;
      }

      if (mantissa) {
        return "" + integer + mantissa;
      } else {
        return "" + integer;
      }
    },

    parseInches(str) {
      const fraction_map = {
        '¼': 0.25, '1/4': 0.25,
        '½': 0.5,  '1/2': 0.5,
        '¾': 0.75, '3/4': 0.75,
      };

      if (typeof str === 'number') return str;
      if (typeof str !== 'string') return NaN;

      str = str.trim();

      // Try to parse directly as float
      const direct = parseFloat(str);
      if (!isNaN(direct)) return direct;

      // Match formats like "5 1/2", "5½", "½", "5 ¾"
      const match = str.match(/^(\d+)?\s*(¼|½|¾|1\/4|1\/2|3\/4)?$/);
      if (!match) return NaN;

      const integer_part = match[1] ? parseInt(match[1], 10) : 0;
      const frac_part = match[2] ? (fraction_map[match[2]] || 0) : 0;

      return integer_part + frac_part;
    },

    inches_to_cm(inches) {
      return Math.round((inches) * 2.54 * 10) / 10; // rounded to 1 decimal cm
    },
  },
};
</script>

<style scoped>
.distance-selector-wrapper {
  padding: 0px;
}
</style>