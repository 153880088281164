<template>
  <div>
    <a id="exercise_config_focus" href="#" />

    <v-card v-if="exercise_launch_dialog" elevation="0" class="exercise_config">
      <v-toolbar v-if="show_toolbar">
        <v-btn
          icon
          @click="
            close_exercise();
            $emit('close');
          "
        >
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ exercise_title(exercise) }}
          <span v-if="config.level"
            >&nbsp;- {{ $t("common.level") }} {{ config.level }}</span
          >
        </v-toolbar-title>
      </v-toolbar>

      <ExerciseLaunch
        ref="exercise_launch"
        :config="config"
        :exercise="exercise"
        :fully_configured="true"
        :config_version="exercise.config_version"
        :custom_instruction="custom_instruction"
        @exercise-done="exercise_launch_dialog = false"
        @exercise-level="exercise_level"
      />

      <v-card-actions>
        <v-btn @click="close_exercise">
          <v-icon left>keyboard_backspace</v-icon>
          {{ $t("common.config") }}
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>

    <v-card v-else elevation="0" class="exercise_config">
      <v-toolbar v-if="show_toolbar">
        <v-btn icon @click="$emit('close')">
          <v-icon>close</v-icon>
        </v-btn>

        <v-toolbar-title>{{ exercise_title(exercise) }}</v-toolbar-title>
        <InfoModal
          v-if="show_additional && exercise_guide(exercise)"
          :title="exercise_title(exercise)"
          :content="exercise_guide(exercise)"
        />
        <v-spacer />

        <ExercisePresetSelect
          v-if="show_preset"
          class="mb-n7 mr-n1"
          dense
          width="350px"
          :exercise_id="exercise.id"
          :current_exercise_config="config"
          :preset_id="current_preset ? current_preset.id : null"
          @preset_selected="preset_selected"
        />
      </v-toolbar>

      <v-card-text>
        <v-alert v-if="exercise.experimental && show_additional" type="info">
          <p>{{ $t("exercise_config.beta_exercise") }}</p>
          <p style="font-size: small" class="mt-n1 mb-1">{{ $t("exercise_config.beta_text") }}</p>
        </v-alert>

        <v-select
          v-if="exercise.num_levels > 1"
          v-model="config.level"
          class="pb-4"
          :items="level_select"
          persistent-hint
          :hint="level_hint"
          :label="$t('common.level')"
          @change="level_change"
        >
          <template slot="item" slot-scope="item">
            {{ item.item.text }}
            <span style="font-size: smaller; position: absolute; right: 30px">
              <span
                v-for="(keyvalue, index) in exercise_level_config(
                  item.item.value
                )"
                :key="index"
              >
                <span v-if="exercise.labels[keyvalue[0]]">
                  <span
                    >{{ exercise.labels[keyvalue[0]].label.en }}:
                    {{ keyvalue[1] }}
                    {{
                      exercise.labels[keyvalue[0]].unit
                        ? exercise.labels[keyvalue[0]].unit.en
                        : ""
                    }}</span
                  >

                  <span
                    v-if="
                      Object.keys(exercise.labels).length > 1 &&
                      index < exercise_level_config_len(item.item.value) - 1
                    "
                    >, &nbsp;</span
                  >
                </span>
              </span>
            </span>
          </template>
        </v-select>

        <component
          :is="exercise.id"
          ref="exerciseConfig"
          v-model="config"
          :regimen_config="regimen_config"
          :show_advanced="show_advanced"
        />
      </v-card-text>

      <v-alert
        v-for="error in errors"
        :key="error"
        :value="true"
        type="error"
        >{{ error }}</v-alert
      >

      <v-card-actions>
        <InfoModal
          ref="protocol_guide_info_modal"
          v-if="show_additional && exercise_guide(exercise) && exercise.version == 2"
          :title="exercise_title(exercise)"
          :content="exercise_guide(exercise)"
          :title_prefix="$t('exercise_config.protocol_guide')"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" @click="$refs.protocol_guide_info_modal.open()">
            <v-icon left color="grey">info</v-icon> {{ $t("exercise_config.protocol_guide") }}
          </v-btn>
        </template>
        </InfoModal>
        <v-spacer />
        <v-btn color="primary" @click="launch_exercise">{{
          $t("common.launch")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import OpticalGymConfigForms from "../../../exercises/OpticalGymConfigForms.js";
import InfoModal from "./InfoModal";
import ExerciseLaunch from "./ExerciseLaunch.vue";
import ExercisePresetSelect from "./ExercisePresetSelect.vue";
import Vue from "vue";

export default {
  components: Object.assign(OpticalGymConfigForms, {
    ExerciseLaunch,
    InfoModal,
    ExercisePresetSelect,
  }),
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    preset: {
      type: Object,
      default: null,
    },
    basepath: {
      type: String,
      default: "/",
    },
    show_advanced: {
      type: Boolean,
      default: true,
    },
    show_additional: {
      type: Boolean,
      default: false,
    },
    show_toolbar: {
      type: Boolean,
      default: true,
    },
    show_preset: {
      type: Boolean,
      default: false,
    },
    default_config: {
      type: Object,
      default: () => ({}),
    },
    regimen_config: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    // Default config for this exercise
    // TODO: Remember this using application storage.
    let config = JSON.parse(JSON.stringify(this.exercise.global));
    if (this.exercise.num_levels > 1) {
      config.level = 1;
      if (config.level <= this.exercise.num_levels) {
        config = Object.assign(config, this.exercise["level" + config.level]);
      }
    }

    // Override with default config
    config = Object.assign(config, this.default_config);

    let data = {
      exercise_launch_dialog: false,
      config: config,
      custom_instruction: "", // Only settable via presets
      current_preset: this.preset,
      errors: [],
    };
    return data;
  },
  computed: {
    level_select: function () {
      let levels = [];
      for (let i = 1; i <= this.exercise.num_levels; i++) {
        levels.push({ value: i, text: this.$t("common.level_list", { level_num: i })});
      }
      return levels;
    },
    level_hint: function () {
      if (this.exercise.num_levels) {
        if (
          "level_hint" in this.exercise &&
          this.config.level < this.exercise.num_levels
        ) {
          let locale = this.$i18n.lang();
          if (this.exercise.level_hint[locale]) {
            return this.exercise.level_hint[locale];
          } else {
            return this.exercise.level_hint["en"];
          }
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  watch: {
    exercise() {
      // Reset config
      this.config = this.exercise.global;
      if (this.exercise.num_levels > 1) {
        this.config.level = 1;
        if (this.config.level <= this.exercise.num_levels) {
          this.config = Object.assign(
            this.config,
            this.exercise["level" + this.config.level]
          );
        }
      }
      this.config = Object.assign(this.config, this.default_config);
    },
    preset: {
      handler: function (preset) {
        this.preset_selected(preset);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    preset_selected(preset) {
      this.current_preset = preset;
      if (preset) {
        Object.assign(this.config, preset.config);
        this.custom_instruction = preset.instructions;
      } else {
        // NULL preset, meaning default config
        let assign_config = JSON.parse(JSON.stringify(this.exercise.global));

        Object.assign(assign_config, this.default_config);

        // Delete level and texturl
        // TODO: texturl et al should be defined in the exercise config params yml
        if ('level' in assign_config) {
          delete assign_config.level;
        }
        if ('texturl' in assign_config) {
          delete assign_config.texturl;
        }

        Object.assign(this.config, assign_config);
        this.custom_instruction = "";

        // Apply local level config
        if (this.exercise.num_levels && this.config.level) {
          Object.assign(
            this.config,
            this.exercise["level" + this.config.level]
          );
        }
      }
    },
    exercise_level_config(level) {
      var sorted = [];
      for (var key in this.exercise["level" + level]) {
        sorted.push([key, this.exercise["level" + level][key]]);
      }
      sorted.sort(function (a, b) {
        return a[0] - b[0];
      });

      return sorted;
    },
    exercise_level_config_len(level) {
      return Object.keys(this.exercise["level" + level]).length;
    },
    exercise_title(exercise) {
      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_guide(exercise) {
      let locale = this.$i18n.lang();
      if (exercise.guide[locale]) {
        return exercise.guide[locale];
      } else if (exercise.guide.en) {
        return exercise.guide.en;
      }
      return "";
    },
    level_change: function () {
      // Override config with level config
      let level_config = this.exercise["level" + this.config.level];
      for (var param in level_config) {
        Vue.set(this.config, param, level_config[param]);
      }
      Vue.delete(this.config, "override_level_params");
    },

    enforce_allowed_values() {
      // Enforce min-max and allowed values
      if (this.exercise.params) {
        for (const [key, param_config] of Object.entries(
          this.exercise.params
        )) {
          if (this.config[key]) {
            if (
              param_config.min !== null &&
              this.config[key] < param_config.min
            ) {
              this.config[key] = param_config.min;
            }
            if (
              param_config.max !== null &&
              this.config[key] > param_config.max
            ) {
              this.config[key] = param_config.max;
            }
            if (
              param_config.allowed !== null &&
              !param_config.allowed.includes(this.config[key])
            ) {
              this.config[key] = this.exercise.global[key];
              if (
                this.exercise.num_levels &&
                this.config.level &&
                this.exercise["level" + this.config.level] &&
                this.exercise["level" + this.config.level][key] !== undefined
              ) {
                this.config[key] =
                  this.exercise["level" + this.config.level][key];
              }
            }
          }
        }
      }
    },

    launch_exercise() {
      let self = this;

      // Enforce allowed values and min / max
      this.enforce_allowed_values();

      // Check for errors
      let exercise = OpticalGymConfigForms[this.exercise.id];
      if (
        typeof exercise.methods !== "undefined" &&
        typeof exercise.methods.validate == "function"
      ) {
        this.errors = exercise.methods.validate(this.config);
      } else {
        this.errors = [];
      }

      // If there are errors, abort the launch
      if (this.errors.length > 0) {
        return;
      }

      this.exercise_launch_dialog = true;
      this.$emit("exercise_launch_dialog", true);
      Vue.nextTick(function () {
        self.$forceUpdate();
        self.$refs.exercise_launch.load();
        self.$emit("exercise-launch", self.config);
      });
    },
    close_exercise() {
      this.exercise_launch_dialog = false;
      this.$emit("exercise_launch_dialog", false);
      this.$refs.exercise_launch.clear_exercise();
    },
    // Triggered on exercise saying the level changed
    exercise_level(new_level) {
      let self = this;
      Vue.set(this.config, "level", new_level);

      // Update level-specified config
      // Override config with level config
      let level_config = this.exercise["level" + this.config.level];
      for (var param in level_config) {
        Vue.set(this.config, param, level_config[param]);
      }

      this.$forceUpdate();
      Vue.nextTick(function () {
        self.$refs.exercise_launch.load();
      });
    },
  },
};
</script>

<style></style>
