<template>
  <div class="text-center mt-6">
    <v-btn v-if="exercise_loaded" x-large dark color="green" @click="start_exercise" style="outline: 1px solid black">
      <v-icon>mdi-play</v-icon>
      {{ $t("common.start") }}
    </v-btn>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    fullscreen_enabled: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    compat: {
      type: Boolean,
      default: () => false,
    },
    unique_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exercise_loaded: false,
      instance: null,
      container: null,
    };
  },
  mounted() {
    this.create_exercise_container();
    this.load_exercise();
  },
  methods: {
    reset: function () {
      this.exercise_loaded = false;
      this.remove_exercise_parent();
    },
    create_exercise_container() {
      this.remove_exercise_parent();
      this.container = document.createElement("div");
      this.container.id = "opticalgym-exercise-container";
      this.container.style.display = "none";
      document.body.appendChild(this.container);
    },
    async load_exercise() {
      try {
        // Use new Function to dynamically create the import statement
        const importPath = `/api/exercise/${this.exercise.id}/${this.exercise.id}.js`;
        const module = await new Function(`return import('${importPath}')`)();
        const ExerciseClass = module.default;
        this.initialize_exercise(ExerciseClass);
      } catch (error) {
        console.error("Failed to load exercise module:", error);
      }
    },
    initialize_exercise(ExerciseClass) {
      this.instance = new ExerciseClass({
        params: this.config,
        lang: this.$i18n.lang(),
        parent: "opticalgym-exercise-container",
        asset_path: `/api/exercise/${this.exercise.id}/assets/`,
        ready: () => {
          this.exercise_loaded = true;
        },
        finished: (report) => {
          this.$emit("exercise-finished", {
            report: report,
            score: report.score,
            level_completed: false, // TODO: read it from the report
            type: "interactive",
          });
          this.clear_exercise();
        },
      });
      window.OpticalGymExerciseInstance = this.instance;
    },
    start_exercise() {
      if (this.instance) {
        this.instance.start();
      }
    },
    clear_exercise: function () {
      this.exercise_loaded = false;
      this.instance = null;
      this.remove_exercise_parent();
      window.OpticalGymExerciseInstance = null;
    },
    remove_exercise_parent() {
      if (this.container) {
        document.body.removeChild(this.container);
        this.container = null;
      }

      // Check if there's a leftover instance of a previous exercise
      const container = document.getElementById("opticalgym-exercise-container");
      if (container) {
        container.parentNode.removeChild(container);
      }
    },
  },
};
</script>
