import rgba from "color-rgba";

export function parseColour(colour: string | number, return_as_int = false, brightness = 1, whiteness = 0): string | number {
  if (return_as_int) {
    return parseColourToInteger(colour, brightness, whiteness);
  } else {
    return parseColourToString(colour, brightness, whiteness);
  }
}

export function inverseColour(colour: string | number, return_as_int = false, bw = false): string | number {
  if (return_as_int) {
    return inverseColourToInteger(colour, bw);
  } else {
    return inverseColourToString(colour, bw);
  }
}

// Parse a colour to an RGB interger, applying brightness and whiteness modifiers
export function parseColourToInteger(colour: string | number, brightness = 1, whiteness = 0): number {
  let colourParsed;
  if (typeof colour === "string") {
    colourParsed = rgba(colour);
  } else {
    colourParsed = splitRGBA(colour);
  }

  if (!colourParsed || colourParsed.length == 0) {
    throw new Error(`Invalid colour: ${colour}`);
  }

  // Apply adjustments
  if (brightness !== 1) {
    colourParsed[0] = Math.round(colourParsed[0] * brightness);
    colourParsed[1] = Math.round(colourParsed[1] * brightness);
    colourParsed[2] = Math.round(colourParsed[2] * brightness);
  }
  if (whiteness) {
    colourParsed[0] = Math.min(255, Math.round(colourParsed[0] + 255 * whiteness));
    colourParsed[1] = Math.min(255, Math.round(colourParsed[1] + 255 * whiteness));
    colourParsed[2] = Math.min(255, Math.round(colourParsed[2] + 255 * whiteness));
  }

  return (colourParsed[0] << 16) + (colourParsed[1] << 8) + colourParsed[2];
}

// Parse a colour to RGB format, applying brightness and whiteness modifiers
export function parseColourToString(colour: string | number, brightness = 1, whiteness = 0): string {
  let colourParsed;
  if (typeof colour === "string") {
    colourParsed = rgba(colour);
  } else {
    colourParsed = splitRGBA(colour);
  }

  if (!colourParsed || colourParsed.length == 0) {
    throw new Error(`Invalid colour: ${colour}`);
  }

  // Apply adjustments
  if (brightness !== 1) {
    colourParsed[0] = Math.round(colourParsed[0] * brightness);
    colourParsed[1] = Math.round(colourParsed[1] * brightness);
    colourParsed[2] = Math.round(colourParsed[2] * brightness);
  }
  if (whiteness) {
    colourParsed[0] = Math.min(255, Math.round(colourParsed[0] + 255 * whiteness));
    colourParsed[1] = Math.min(255, Math.round(colourParsed[1] + 255 * whiteness));
    colourParsed[2] = Math.min(255, Math.round(colourParsed[2] + 255 * whiteness));
  }

  return `rgb(${colourParsed[0]},${colourParsed[1]},${colourParsed[2]})`;
}

// Get the inverse of a colour, outputted as an RGB stirng
// If `bw` is set to true, return either black or white depending on the colour brightness.
export function inverseColourToString(colour: string | number, bw = false): string {
  let colourParsed;
  if (typeof colour === "string") {
    colourParsed = rgba(colour);
  } else {
    colourParsed = splitRGBA(colour);
  }

  if (!colourParsed || colourParsed.length == 0) {
    throw new Error(`Invalid colour: ${colour}`);
  }

  let r = colourParsed[0];
  let g = colourParsed[1];
  let b = colourParsed[2];

  if (bw) {
    // Using luminance to decide black or white contrast
    // http://stackoverflow.com/a/3943023/112731
    const luminance = r * 0.299 + g * 0.587 + b * 0.114;
    if (luminance > 186) {
      return "#000000";
    } else {
      return "#ffffff";
    }
  }

  // Invert colour components
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  return `#${padZero(r.toString(16))}${padZero(g.toString(16))}${padZero(b.toString(16))}`;
}

// Get the inverse of a colour, outputted as an Integer
// If `bw` is set to true, return either black or white depending on the colour brightness.
export function inverseColourToInteger(colour: string | number, bw = false): number {
  let colourParsed;
  if (typeof colour === "string") {
    colourParsed = rgba(colour);
  } else {
    colourParsed = splitRGBA(colour);
  }

  if (!colourParsed || colourParsed.length == 0) {
    throw new Error(`Invalid colour: ${colour}`);
  }

  let r = colourParsed[0];
  let g = colourParsed[1];
  let b = colourParsed[2];

  if (bw) {
    // Using luminance to decide black or white contrast
    // http://stackoverflow.com/a/3943023/112731
    const luminance = r * 0.299 + g * 0.587 + b * 0.114;
    if (luminance > 186) {
      return 0;
    } else {
      return 16777215;
    }
  }

  // Invert colour components
  r = 255 - r;
  g = 255 - g;
  b = 255 - b;

  return (r << 16) + (g << 8) + b;
}

function padZero(str: string, len: number = 2): string {
  len = len || 2;
  var zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

// Split a 32bit packed integer colour into its RGBA components
function splitRGBA(color: number): [number, number, number, number] {
  if (color <= 0xffffff) {
    // Treat it as RGB and assume full opacity for alpha
    const r = (color >> 16) & 0xff;
    const g = (color >> 8) & 0xff;
    const b = color & 0xff;
    return [r, g, b, 255]; // Default alpha to 255
  } else {
    // Treat it as RGBA
    const r = (color >> 24) & 0xff;
    const g = (color >> 16) & 0xff;
    const b = (color >> 8) & 0xff;
    const a = color & 0xff;
    return [r, g, b, a];
  }
}
