<template>
  <span>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <div @open="dialog = true">
          <slot name="activator" :on="on" :attrs="attrs" :open_info_modal="() => dialog = true">
            <v-btn v-bind="attrs" color="grey" icon v-on="on" @click="dialog = true">
              <v-icon>info</v-icon>
            </v-btn>
          </slot>
        </div>
      </template>

      <span>
        <span v-if="!hide_title_prefix">{{ title_prefix }}:</span>
        {{ title }}
      </span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="900">
      <v-card v-if="dialog">
        <v-toolbar>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>

          <v-toolbar-title>
            <span v-if="!hide_title_prefix">{{ title_prefix }}:</span>
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <div class="info_modal_content" v-html="content"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    title_prefix: {
      type: String,
      default: function() {
        return this.$t("common.more_information")
      },
    },
    hide_title_prefix: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style>
.info_modal_content {
  font-size: large;
  margin-top: 20px;
  line-height: normal;
  min-height: 500px;
}

.info_modal_content h1 {
  margin-top: 40px;
  margin-bottom: 25px;
}

.info_modal_content h2 {
  margin-top: 35px;
  margin-bottom: 25px;
}

.info_modal_content h3 {
  margin-top: 30px;
  margin-bottom: 20px;
}
.info_modal_content h4 {
  margin-top: 25px;
  margin-bottom: 15px;
}
</style>